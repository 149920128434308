.date-picker, .MuiFormControl-root.date-picker {
    max-width: var(--datepicker-width);
    min-width: var(--datepicker-width);
}


.MuiCalendarPicker-root :is(.MuiDayPicker-weekDayLabel, .MuiButtonBase-root.MuiPickersDay-root) {
    font-size: 1rem;
}

.MuiCalendarPicker-root .MuiButtonBase-root.MuiPickersDay-root {
    color: var(--primary-text-color);
}

.MuiCalendarPicker-root .MuiButtonBase-root.MuiPickersDay-root:is(:hover, :focus) {
    background-color: var(--secondary-background-color);
}

.MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected {
    background-color: transparent;
    border: 2px solid var(--primary-color);
}

.MuiCalendarPicker-root .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected {
    background-color: var(--primary-color);
}
