.toggle-container .relativeContentWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-container input {
    position: absolute;
    right: 0;
    width: 0;
    margin: 0;
    border: 0;
    padding: 0;
    height: 0;
    scale: 0;
    overflow: hidden;
}

.toggle-container label {
    margin-right: 60px;
    display: flex;
    align-items: center;
}

.toggle-container input:not(:disabled) + label {
    cursor: pointer;
}

.toggle-container input:focus-visible + label::before {
    box-shadow: var(--focus-shadow);
}

.toggle-container input:focus-visible + label::after {
    box-shadow: var(--focus-shadow);
}

.toggle-container label::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 30px;
    border-radius: 15px;
    background: var(--primary-neutral-color);
    right: 0;
    transition: var(--animation-speed) ease-in-out;
}

.toggle-container label::after {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background: var(--primary-background-color);
    border: 1px solid var(--primary-neutral-color);
    right: 20px;
    transition: var(--animation-speed) ease-in-out;
}

.toggle-container input[type="checkbox"]:checked + label::before {
    background: var(--primary-color);
}

.toggle-container input[type="checkbox"]:checked + label::after {
    content: "";
    right: 0;
    border-color: var(--primary-color);
}

.drawerCard .toggle-container {
    flex-grow: 1;
}
