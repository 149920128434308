.subcontractingRelationData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
            "principal      principal       principal       .                   .                   ."
            "subcontractor  subcontractor   subcontractor   standard            standard            ."
            "accessLevel    accessLevel     accessLevel     confirmationMethod  confirmationMethod  confirmationMethod"
            "startDate      endDate         .               .                   .                   .";
}

.subcontractingRelationData .principal {
    grid-area: principal;
    --width-by-chars: 40;
}

.subcontractingRelationData .subcontractor {
    grid-area: subcontractor;
    --width-by-chars: 40;
}

.subcontractingRelationData .standard {
    grid-area: standard;
    --width-by-chars: 40;
}

.subcontractingRelationData .accessLevel {
    grid-area: accessLevel;
}

.subcontractingRelationData .confirmationMethod {
    grid-area: confirmationMethod;
}

.subcontractingRelationData .startDate {
    grid-area: startDate;
}

.subcontractingRelationData .endDate {
    grid-area: endDate;
}
