footer {
    height: var(--footer-height);
    display: flex;
    align-items: center;
    color: var(--footer-text-color);
    background-color: var(--footer-background-color);
    padding: 0 var(--horizontal-window-spacing);
}

footer ul {
    display: flex;
    margin-right: auto;
}

footer ul li:first-of-type {
    margin-left: 0;
}

footer ul li {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

footer .language-pick-list {
    /* A small width-by-chars is necessary because the space of the (not shown) clear button remains in the input */
    --width-by-chars: 2;
}

footer span {
    color: var(--footer-text-color) !important;
}

footer p {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    font-size: var(--14pt);
}

footer .footerVersion {
    font-size: var(--12pt);
    margin-left: auto;
}

footer input {
    color: var(--footer-text-color) !important;
}

footer button {
    color: var(--footer-text-color) !important;
}

footer a {
    color: var(--footer-text-color);
    text-decoration: none;
}

footer .zendesk-button {
    color: var(--footer-text-color);
    text-decoration: none;
    background: none;
    border: none;
    font-size: var(--16pt);
}
