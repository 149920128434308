.externalOrganicMaterialProducerIdData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "organicMaterialProducer    organicMaterialProducer organicMaterialProducer ."
            "type                       certificationBody       certificationBody       ."
            "externalId                 externalId              .                       .";
}

.externalOrganicMaterialProducerIdData .organicMaterialProducer {
    grid-area: organicMaterialProducer;
    --width-by-chars: 40;
}
