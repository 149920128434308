.default-style {
    white-space: normal;
}

.default-style * {
    all: revert;
}

.markdown-container {
    color: var(--primary-text-color);
}

.markdown-container table {
    border: 1px solid var(--header-border-color);
    border-collapse: collapse;
    width: 100%
}

.markdown-container th {
    border: 1px solid var(--header-border-color);
    background-color: var(--secondary-background-color);
    color: var(--main-menu-text-color);
    padding: var(--small-padding) var(--textfield-horizontal-padding);
}

.markdown-container td {
    border: 1px solid var(--header-border-color);
    padding: var(--small-padding) var(--textfield-horizontal-padding);
}

.markdown-container hr {
    color: var(--header-border-color);
}

.markdown-container blockquote {
    border-left: 5px solid var(--header-border-color);
    background-color: var(--secondary-background-color);
    padding: 0.5em 10px;
}

.markdown-container code {
    padding: 2px 4px;
    background-color: var(--secondary-background-color);
    border-radius: var(--component-border-radius);
}

.markdown-container pre {
    background-color: var(--secondary-background-color);
    border-radius: var(--component-border-radius);
    padding: var(--small-padding);
}
