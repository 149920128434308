.accreditationRelationData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
            "certificationBody      certificationBody       certificationBody   accreditationBody   standardVersion   ."
            "accreditationNumber    accreditationNumber     .                   .                   .                 ."
            "accreditedScopes       accreditedScopes        accreditedScopes    accreditedScopes    accreditedScopes  ."
            "geographicScopes       geographicScopes        geographicScopes    geographicScopes    geographicScopes  ."
            "startDate              endDate                 .                   .                   .                 .";
}

.accreditationRelationData .certificationBody {
    grid-area: certificationBody;
}

.accreditationRelationData .accreditationBody {
    grid-area: accreditationBody;
}

.accreditationRelationData .standardVersion {
    grid-area: standardVersion;
}

.accreditationRelationData .accreditationNumber {
    grid-area: accreditationNumber;
    /* instead of 15 because of label length */
    --width-by-chars: 18;
}

.accreditationRelationData .accreditedScopes {
    grid-area: accreditedScopes;
}

.accreditationRelationData .geographicScopes {
    grid-area: geographicScopes;
}

.accreditationRelationData .startDate {
    grid-area: startDate;
}

.accreditationRelationData .endDate {
    grid-area: endDate;
}
