.certificationBodyPriorConsentData {
    grid-template-columns: min-content 1fr;
    grid-template-areas:
            "priorConsentDescription    priorConsentDescription"
            "priorConsent               .";
}

.certificationBodyPriorConsentData .priorConsent {
    grid-area: priorConsent;
    --width-by-chars: 150;
}

.certificationBodyPriorConsentData .priorConsentDescription {
    grid-area: priorConsentDescription;
}