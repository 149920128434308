.loginData {
    grid-template-columns: min-content min-content 1fr;
    grid-template-areas:
            "successfulLogin failedLogin    ."
            "totp            totp           ."
            "changePassword  changePassword ."
}

.loginData.withTou {
    grid-template-areas:
            "successfulLogin failedLogin        ."
            "acceptedToU     acceptedGoogleToU  acceptedGoogleToU"
            "totp            totp               ."
            "changePassword  changePassword     ."
}

.loginData.withTestDataStamp {
    grid-template-areas:
            "successfulLogin failedLogin    ."
            "testDataStamp   testDataStamp  ."
            "totp            totp           ."
            "changePassword  changePassword ."
}

.loginData.withTou.withTestDataStamp {
    grid-template-areas:
            "successfulLogin failedLogin        ."
            "acceptedToU     acceptedGoogleToU  acceptedGoogleToU"
            "testDataStamp   testDataStamp      ."
            "totp            totp               ."
            "changePassword  changePassword     ."
}

.loginData .successfulLogin {
    grid-area: successfulLogin;
    --width-by-chars: 15; /*  for label inside field  */
    max-width: unset;
}

.loginData .failedLogin {
    grid-area: failedLogin;
    --width-by-chars: 15; /*  for label inside field  */
    max-width: unset;
}

.loginData .testDataStamp {
    grid-area: testDataStamp;
    --width-by-chars: 40;
}

.loginData .totpReady {
    min-width: 8rem;
}

.loginData .totp {
    grid-area: totp;
    display: flex;
    gap: var(--layout-horizontal-spacing);
}

.loginData .activateTotpButton {
    width: 100%;
}

.loginData .resetTotpButton {
    width: 100%;
}

.loginData .changePassword {
    grid-area: changePassword;
    width: 100%;
}

.loginData .acceptedToU {
    grid-area: acceptedToU;
}

.loginData .acceptedGoogleToU {
    grid-area: acceptedGoogleToU;
}
