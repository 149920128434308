.static-page-view-layout {
    height: 100vh;
    min-height: 400px;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
            "banner"
            "content"
            "footer"
}

.static-page-view-layout header {
    grid-area: banner;
}

.static-page-view-layout header .environment-name {
    padding-right: 50px;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    color: var(--warning-color);
    font-size: var(--14pt);
}

.static-page-view-layout footer {
    grid-area: footer;
}

.static-page-view-content {
    grid-area: content;
    padding: 40px 40px 25px 40px;
    overflow: auto;
}

.static-page-view-content .page-title {
    font-size: var(--50pt);
    margin: 0;
}

.static-page-view-content .markdown-container {
    max-width: 1200px;
}