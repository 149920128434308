.Toastify .notification-progress {
    background: var(--primary-neutral-color);
}

.Toastify .notification {
    color: var(--primary-text-color);
}

.Toastify .info-notification {
    background-color: var(--info-background-color);
}

.Toastify .warning-notification {
    background-color: var(--warning-background-color);
}

.Toastify .error-notification {
    background-color: var(--error-background-color);
}
