.scMaterialBasicData {
    grid-template-columns: repeat(6, min-content) 1fr;
    grid-template-areas:
        "supplyChainOperator    supplyChainOperator    scProductNumber         .                       .                       .               ."
        "productCategory        productCategory        productDetail           productDetail           labelGrade              labelGrade      ."
        "material               material               materialPrint           materialPrint           materialPrint           .               ."
        "minPercentage          maxPercentage          .                       .                       .                       .               .";
}

.scMaterialBasicData .supplyChainOperator {
    grid-area: supplyChainOperator;
    --width-by-chars: 40;
}

.scMaterialBasicData .scProductNumber {
    grid-area: scProductNumber;
    --width-by-chars: 16;
}

.scMaterialBasicData .productCategory {
    grid-area: productCategory;
    --width-by-chars: 40;
}

.scMaterialBasicData .productDetail {
    grid-area: productDetail;
    --width-by-chars: 40;
}

.scMaterialBasicData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.scMaterialBasicData .material {
    grid-area: material;
    --width-by-chars: 40;
}

.scMaterialBasicData .materialPrint {
    grid-area: materialPrint;
    --width-by-chars: 50;
}

.scMaterialBasicData .minPercentage {
    grid-area: minPercentage;
}

.scMaterialBasicData .maxPercentage {
    grid-area: maxPercentage;
}