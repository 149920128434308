.preGtbProductBasicData {
    grid-template-columns: repeat(3, min-content) minmax(min-content, 1fr);
    grid-template-areas:
            "tcNumber           productNumber               .                   ."
            "productCategory    productCategoryPrint        .                   ."
            "productDetail      productDetailPrint          .                   ."
            "netShippingWeight  supplementaryWeight         certifiedWeight     certifiedWeightDescription"
            "availableVolume    initialAvailableQuantity    usdaNopCompliant    .";
}

.preGtbProductBasicData.withLabelGrade {
    grid-template-areas:
            "tcNumber           productNumber               .                   ."
            "productCategory    productCategoryPrint        .                   ."
            "productDetail      productDetailPrint          .                   ."
            "labelGrade         .                           .                   ."
            "netShippingWeight  supplementaryWeight         certifiedWeight     certifiedWeightDescription"
            "availableVolume    initialAvailableQuantity    usdaNopCompliant    .";
}

.preGtbProductBasicData .tcNumber {
    grid-area: tcNumber;
    --width-by-chars: 30;
}

.preGtbProductBasicData .productNumber {
    grid-area: productNumber;
    --width-by-chars: 40;
}

.preGtbProductBasicData .productCategory {
    grid-area: productCategory;
}

.preGtbProductBasicData .productCategoryPrint {
    grid-area: productCategoryPrint;
    --width-by-chars: 50;
}

.preGtbProductBasicData .productDetail {
    grid-area: productDetail;
}

.preGtbProductBasicData .productDetailPrint {
    grid-area: productDetailPrint;
    --width-by-chars: 50;
}

.preGtbProductBasicData .labelGrade {
    grid-area: labelGrade;
}

.preGtbProductBasicData .netShippingWeight {
    grid-area: netShippingWeight;
}

.preGtbProductBasicData .supplementaryWeight {
    grid-area: supplementaryWeight;
}

.preGtbProductBasicData .certifiedWeight {
    grid-area: certifiedWeight;
    --width-by-chars: 20;
}

.preGtbProductBasicData #certifiedWeightDescription {
    grid-area: certifiedWeightDescription;
    align-self: center;
}

.preGtbProductBasicData .availableVolume {
    grid-area: availableVolume;
}

.preGtbProductBasicData .initialAvailableQuantity {
    grid-area: initialAvailableQuantity;
}

.preGtbProductBasicData .usdaNopCompliant {
    grid-area: usdaNopCompliant;
}
