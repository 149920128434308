.supplyingRelationData {
    grid-template-columns: repeat(4, min-content) 1fr;
    grid-template-areas:
            "supplyChainOperator        supplyChainOperator         supplyChainOperator         .               ."
            "organicMaterialProducer    organicMaterialProducer     organicMaterialProducer     .               ."
            "accessLevel                accessLevel                 accessLevel                 accessLevel     ."
            "startDate                  endDate                     .                           .               .";
}

.supplyingRelationData .supplyChainOperator {
    grid-area: supplyChainOperator;
}

.supplyingRelationData .organicMaterialProducer {
    grid-area: organicMaterialProducer;
}

.supplyingRelationData .accessLevel {
    grid-area: accessLevel;
}

.supplyingRelationData .startDate {
    grid-area: startDate;
}

.supplyingRelationData .endDate {
    grid-area: endDate;
}
