.rawMaterialBasicData {
    grid-template-columns: repeat(4, auto) 1fr;
    grid-template-areas:
            "code               code                code                pickListSequence    ."
            "name               name                name                typeOfMaterial      ."
            "startDate          endDate             .                   printNameMandatory  ."
            "allowedMaterial    allowedMaterial     allowedMaterial     allowedMaterial     allowedMaterial";
}

.rawMaterialBasicData .code {
    grid-area: code;
    --width-by-chars: 6;
}

.rawMaterialBasicData .pickListSequence {
    grid-area: pickListSequence;
}

.rawMaterialBasicData .printNameMandatory {
    grid-area: printNameMandatory;
}

.rawMaterialBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.rawMaterialBasicData .typeOfMaterial {
    grid-area: typeOfMaterial;
}

.rawMaterialBasicData .startDate {
    grid-area: startDate;
}

.rawMaterialBasicData .endDate {
    grid-area: endDate;
}

.rawMaterialBasicData .allowedMaterial {
    grid-area: allowedMaterial;
}
