.tcProductQuantitativeData {
    grid-template-columns: repeat(6, min-content) 1fr;
    grid-template-areas:
            "productCategoryPrint   productCategoryPrint    productDetailPrint          productDetailPrint  labelGrade                  labelGrade          ."
            "numberOfUnits          unitName                .                           .                   .                           .                   ."
            "netShippingWeight      netShippingWeight       supplementaryWeight         certifiedWeight     certifiedWeightDescription  nonCertifiedWeight  ."
            "availableQuantity      byProductWeight         availableByProductQuantity  .                   .                           .                   ."
            "inputQuantities        inputQuantities         inputQuantities             inputQuantities     inputQuantities             inputQuantities     inputQuantities";
}

.tcProductQuantitativeData .productCategoryPrint {
    grid-area: productCategoryPrint;
    --width-by-chars: 40;
}

.tcProductQuantitativeData .productDetailPrint {
    grid-area: productDetailPrint;
    --width-by-chars: 40;
}

.tcProductQuantitativeData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.tcProductQuantitativeData .numberOfUnits {
    grid-area: numberOfUnits;
    --width-by-chars: 10;
}

.tcProductQuantitativeData .unitName {
    grid-area: unitName;
    --width-by-chars: 20;
}

.tcProductQuantitativeData .netShippingWeight {
    grid-area: netShippingWeight;
}

.tcProductQuantitativeData .supplementaryWeight {
    grid-area: supplementaryWeight;
    --width-by-chars: 16;
}

.tcProductQuantitativeData .certifiedWeight {
    grid-area: certifiedWeight;
    --width-by-chars: 8;
}

.tcProductQuantitativeData .nonCertifiedWeight {
    grid-area: nonCertifiedWeight;
    --width-by-chars: 20;
}

.tcProductQuantitativeData .availableQuantity {
    grid-area: availableQuantity;
}

.tcProductQuantitativeData .byProductWeight {
    grid-area: byProductWeight;
}

.tcProductQuantitativeData .availableByProductQuantity {
    grid-area: availableByProductQuantity;
}

.tcProductQuantitativeData #certifiedWeightDescription {
    grid-area: certifiedWeightDescription;
    align-self: center;
}

.tcProductQuantitativeData .inputQuantities {
    grid-area: inputQuantities;
}
