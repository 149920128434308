.standardBodyBasicData {
    grid-template-columns: repeat(9, min-content) 1fr;
    grid-template-areas:
            "legalName      legalName       legalName       legalName               legalName               logo                    logo                        logo                        ."
            "businessName   businessName    businessName    .                       .                       .                       .                           .                           ."
            "website        website         website         organisationalEmail     organisationalEmail     organisationalEmail     organisationalPhoneNumber   organisationalPhoneNumber   ."
            "startDate      endDate         .               status                  .                       .                       .                           .                           .";
}

.standardBodyBasicData .legalName {
    grid-area: legalName;
    --width-by-chars: 80;
}

.standardBodyBasicData .logo {
    grid-area: logo;
    --width-by-chars: 80;
}
