.byproductInputCombinationBasicData {
    grid-template-columns: max-content 1fr;
    grid-template-areas:
            "productDetail      ."
            "processCategory    .";
}

.byproductInputCombinationBasicData .productDetail {
    grid-area: productDetail;
    --width-by-chars: 40;
}

.byproductInputCombinationBasicData .processCategory {
    grid-area: processCategory;
    --width-by-chars: 40;
}
