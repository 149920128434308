.mainMenu {
    height: var(--mainMenuHeight);
    padding-left: var(--horizontal-window-spacing);
    border-bottom: 1px solid var(--header-border-color);
    background-color: var(--main-menu-background-color);
    color: var(--main-menu-text-color);
}

.mainMenu ul {
    height: 100%;
    display: flex;
    margin: 0;
}

.mainMenu .mainMenuItem:first-of-type {
    margin-left: 0;
}

.mainMenu .mainMenuItem {
    height: 100%;
    margin: auto 20px;
}

.mainMenu .dropdownWrapper {
    height: 100%;
}

.mainMenu .mainMenuItem .openMainMenuButton {
    border: none;
    height: 100%;
    padding: 0;
    font-size: var(--18pt);
    background: transparent;
    font-weight: bold;
    color: currentColor;
}

.mainMenu .mainMenuItem button {
    color: currentColor;
}

.mainMenuItem svg {
    margin: 0 0 0 6px;
}
