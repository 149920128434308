.gridFilter > .MuiTextField-root {
    width: 100%;
}

.gridFilter .MuiTextField-root input, .MuiAutocomplete-root.gridFilter .pick-list input.MuiAutocomplete-input {
    height: 1rem;
    padding: 0 0;
    font-size: var(--14pt);
    font-weight: bold;
    color: var(--primary-text-color);
}

.gridFilter .MuiTextField-root input::placeholder {
    color: var(--list-column-header-color);
    opacity: 1;
}

.gridFilter .MuiTextField-root .MuiInputAdornment-root {
    color: var(--list-column-header-color);
}

.gridFilter .MuiInputBase-root, .gridFilter .pick-list .MuiInputBase-root {
    padding: 0;
}

.gridFilter fieldset {
    border: none;
}

.gridFilter .MuiFormHelperText-root {
    white-space: normal;
}
