.scopeCertificateProductBasicData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "supplyChainOperator    scProductNumber         cbScProductReferenceNumber  ."
            "productCategory        productCategoryPrint    productCategoryPrint        ."
            "productDetail          productDetailPrint      productDetailPrint          ."
            "labelGrade             .                       .                           .";
}

.scopeCertificateProductBasicData .supplyChainOperator {
    grid-area: supplyChainOperator;
}

.scopeCertificateProductBasicData .scProductNumber {
    grid-area: scProductNumber;
    --width-by-chars: 16;
}

.scopeCertificateProductBasicData .cbScProductReferenceNumber {
    grid-area: cbScProductReferenceNumber;
    --width-by-chars: 30;
}

.scopeCertificateProductBasicData .productCategory {
    grid-area: productCategory;
}

.scopeCertificateProductBasicData .productCategoryPrint {
    grid-area: productCategoryPrint;
    --width-by-chars: 50;
}

.scopeCertificateProductBasicData .productDetail {
    grid-area: productDetail;
}

.scopeCertificateProductBasicData .productDetailPrint {
    grid-area: productDetailPrint;
    --width-by-chars: 50;
}

.scopeCertificateProductBasicData .labelGrade {
    grid-area: labelGrade;
}
