.downtimeNotification {
    position: sticky;
    top: 0;
    left: 0;
    min-width: 100vw;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--20pt);
    color: var(--downtime-notification-color);
    background: var(--downtime-notification-background-color);
    border-bottom: 3px solid;
    white-space: nowrap;
    overflow: auto;
}

.downtimeNotification ~ * {
    max-height: calc(100vh - 3rem);
}
