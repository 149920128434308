.scopeCertificateBasicData {
    grid-template-columns: var(--datepicker-width) var(--datepicker-width) repeat(5, min-content) 1fr;
    grid-template-areas:
        "supplyChainOperator        supplyChainOperator         supplyChainOperator         scNumber                    revisionNumber              .                   .                   ."
        "standardVersion            standardVersion             standardVersion             certificationBody           certificationBody           certificationBody   cbScReferenceNumber ."
        "validFrom                  validUntil                  status                      status                      .                           .                   .                   ."
        "dateOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue        approvingPerson     ."
        "recertificationExpected    recertificationExpected     recertificationExpiryDate   .                           .                           .                   .                   .";
}

.scopeCertificateBasicData.withRecertifyButton {
    grid-template-areas:
        "supplyChainOperator        supplyChainOperator         supplyChainOperator         scNumber                    revisionNumber              .                   .                   ."
        "standardVersion            standardVersion             standardVersion             certificationBody           certificationBody           certificationBody   cbScReferenceNumber ."
        "validFrom                  validUntil                  status                      status                      .                           .                   .                   ."
        "dateOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue        approvingPerson     ."
        "recertificationExpected    recertificationExpected     recertify                   recertificationExpiryDate   .                           .                   .                   .";
}

.scopeCertificateBasicData.withRevisionButton {
    grid-template-areas:
        "supplyChainOperator        supplyChainOperator         supplyChainOperator         scNumber                    revisionNumber              .                   .                   ."
        "standardVersion            standardVersion             standardVersion             certificationBody           certificationBody           certificationBody   cbScReferenceNumber ."
        "validFrom                  validUntil                  status                      status                      .                           .                   .                   ."
        "dateOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue        approvingPerson     ."
        "recertificationExpected    recertificationExpected     revisionButton              recertificationExpiryDate   .                           .                   .                   .";
}

.scopeCertificateBasicData.withRecertifyButton.withRevisionButton {
    grid-template-areas:
        "supplyChainOperator        supplyChainOperator         supplyChainOperator         scNumber                    revisionNumber              .                   .                   ."
        "standardVersion            standardVersion             standardVersion             certificationBody           certificationBody           certificationBody   cbScReferenceNumber ."
        "validFrom                  validUntil                  status                      status                      .                           .                   .                   ."
        "dateOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue                placeOfIssue        approvingPerson     ."
        "recertificationExpected    recertificationExpected     recertify                   revisionButton              recertificationExpiryDate   .                   .                   .";
}

.scopeCertificateBasicData .supplyChainOperator {
    grid-area: supplyChainOperator;
}

.scopeCertificateBasicData .scNumber {
    grid-area: scNumber;
    --width-by-chars: 12;
}

.scopeCertificateBasicData .revisionNumber {
    grid-area: revisionNumber;
    --width-by-chars: 12;
}

.scopeCertificateBasicData .standardVersion {
    grid-area: standardVersion;
}

.scopeCertificateBasicData .certificationBody {
    grid-area: certificationBody;
}

.scopeCertificateBasicData .cbScReferenceNumber {
    grid-area: cbScReferenceNumber;
    --width-by-chars: 60;
}

.scopeCertificateBasicData .validFrom {
    grid-area: validFrom;
}

.scopeCertificateBasicData .validUntil {
    grid-area: validUntil;
}

.scopeCertificateBasicData .status {
    grid-area: status;
}

.scopeCertificateBasicData .dateOfIssue {
    grid-area: dateOfIssue;
}

.scopeCertificateBasicData .placeOfIssue {
    grid-area: placeOfIssue;
    --width-by-chars: 80;
}

.scopeCertificateBasicData .approvingPerson {
    grid-area: approvingPerson;
}

.scopeCertificateBasicData .recertificationExpected {
    grid-area: recertificationExpected;
}

.scopeCertificateBasicData .recertificationExpiryDate {
    grid-area: recertificationExpiryDate;
    max-width: unset;
    --width-by-chars: 18;
}

.scopeCertificateBasicData .recertify {
    grid-area: recertify;
}

.scopeCertificateBasicData .revisionButton {
    grid-area: revisionButton;
}
