.shipmentBasicData {
    grid-template-columns: repeat(2, min-content) max-content 1fr;
    grid-template-areas:
        "shipmentNumber         shipmentDocumentNumber  shipmentDate    ."
        "seller                 facilityAsSeller        .               ."
        "buyer                  facilityAsBuyer         .               ."
        "grossShippingWeight    countryOfDestination    .               ."
        "invoiceNumbersAndDates consignee               consignee       .";
}

.shipmentBasicData .shipmentNumber {
    grid-area: shipmentNumber;
}

.shipmentBasicData .shipmentDocumentNumber {
    grid-area: shipmentDocumentNumber;
}

.shipmentBasicData .shipmentDate {
    grid-area: shipmentDate;
}

.shipmentBasicData .seller {
    grid-area: seller;
    --width-by-chars: 40;
}

.shipmentBasicData .facilityAsSeller {
    grid-area: facilityAsSeller;
}

.shipmentBasicData .buyer {
    grid-area: buyer;
    --width-by-chars: 40;
}

.shipmentBasicData .facilityAsBuyer {
    grid-area: facilityAsBuyer;
}

.shipmentBasicData .grossShippingWeight {
    grid-area: grossShippingWeight;
}

.shipmentBasicData .countryOfDestination {
    grid-area: countryOfDestination;
}

.shipmentBasicData .invoiceNumbersAndDates {
    grid-area: invoiceNumbersAndDates;
    --width-by-chars: 40;
}

.shipmentBasicData .consignee {
    grid-area: consignee;
    display: grid;
    grid-template-columns: subgrid;
}

.consignee .inputComponentWrapper {
    display: grid;
    gap: 0;
    grid-template-columns: subgrid;
    grid-column: 1 / 3;
}

.consignee .gtb-textarea {
    grid-column: 1 / 2;
}
