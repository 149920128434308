.organicMaterialProducerBasicData {
    grid-template-columns: repeat(8, min-content) 1fr;
}

.organicMaterialProducerBasicData.gridExcludingLegalNameInEnglish {
    grid-template-areas:
            "legalName      legalName       legalName       legalName           legalName           .                   .                           ."
            "businessName   businessName    businessName    businessName        businessName        .                   .                           ."
            "website        website         website         organisationalEmail organisationalEmail organisationalEmail organisationalPhoneNumber   ."
            "startDate      endDate         .               status              .                   .                   .                           .";
}

.organicMaterialProducerBasicData.gridIncludingLegalNameInEnglish {
    grid-template-areas:
            "legalName              legalName           legalName           legalName           legalName           .                   .                           ."
            "legalNameInEnglish     legalNameInEnglish  legalNameInEnglish  legalNameInEnglish  legalNameInEnglish  .                   .                           ."
            "businessName           businessName        businessName        businessName        businessName        .                   .                           ."
            "website                website             website             organisationalEmail organisationalEmail organisationalEmail organisationalPhoneNumber   ."
            "startDate              endDate             .                   status              .                   .                   .                           .";
}

.organicMaterialProducerBasicData .legalName {
    grid-area: legalName;
    --width-by-chars: 80;
}

.organicMaterialProducerBasicData .legalNameInEnglish {
    grid-area: legalNameInEnglish;
    --width-by-chars: 80;
}
