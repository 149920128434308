.detailViewLoadingPlaceholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailView {
    display: flex;
    flex-direction: column;
}
