.singleSelectList tbody tr {
    cursor: pointer;
}

.singleSelectList tbody tr:hover, .singleSelectList tbody tr[aria-selected="true"] {
    background-color: var(--main-menu-background-color);
    color: var(--dropdown-text-highlight-color);
}

.singleSelectList tbody tr:focus-visible {
    outline: transparent;
    box-shadow: var(--focus-shadow) inset;
    background-color: var(--main-menu-background-color);
    color: var(--dropdown-text-highlight-color);
}
