.transactionCertificateBasicData {
    grid-template-columns: var(--datepicker-width) repeat(5, min-content) 1fr;
    grid-template-areas:
        "certificationBody  certificationBody   cbTcReferenceNumber  cbTcReferenceNumber    .                   .                   ."
        "seller             seller              facilityAsSeller     facilityAsSeller       facilityAsSeller    .                   ."
        "buyer              buyer               facilityAsBuyer      facilityAsBuyer        facilityAsBuyer     .                   ."
        "tcNumber           status              scopeCertificate     scopeCertificate       scopeCertificate    .                   ."
        "dateOfIssue        placeOfIssue        placeOfIssue         placeOfIssue           placeOfIssue        approvingPerson     .";
}

.transactionCertificateBasicData .certificationBody {
    grid-area: certificationBody;
}

.transactionCertificateBasicData .cbTcReferenceNumber {
    grid-area: cbTcReferenceNumber;
    --width-by-chars: 30;
}

.transactionCertificateBasicData .seller {
    grid-area: seller;
}

.transactionCertificateBasicData .standardVersion {
    grid-area: standardVersion;
}

.transactionCertificateBasicData .facilityAsSeller {
    grid-area: facilityAsSeller;
}

.transactionCertificateBasicData .buyer {
    grid-area: buyer;
}

.transactionCertificateBasicData .facilityAsBuyer {
    grid-area: facilityAsBuyer;
}

.transactionCertificateBasicData .tcNumber {
    grid-area: tcNumber;
    --width-by-chars: 12;
}

.transactionCertificateBasicData .status {
    grid-area: status;
}

.transactionCertificateBasicData .scopeCertificate {
    grid-area: scopeCertificate;
}

.transactionCertificateBasicData .dateOfIssue {
    grid-area: dateOfIssue;
}

.transactionCertificateBasicData .placeOfIssue {
    grid-area: placeOfIssue;
    --width-by-chars: 40;
}

.transactionCertificateBasicData .approvingPerson {
    grid-area: approvingPerson;
}
