.inputQuantityBasicData {
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-template-areas:
            "inputNumber        sourceType          sourceType"
            "quantityToBeUsed   .                   ."
            "inputSource        inputSource         ."
            "inputSourceSubList inputSourceSubList  inputSourceSubList";
}

.inputQuantityBasicData .inputNumber {
    --width-by-chars: 20;
    grid-area: inputNumber;
}

.inputQuantityBasicData .sourceType {
    grid-area: sourceType;
}

.inputQuantityBasicData .quantityToBeUsed {
    grid-area: quantityToBeUsed;
}

.inputQuantityBasicData .inputSource {
    grid-area: inputSource;
}

.inputQuantityBasicData .inputSourceSubList {
    grid-area: inputSourceSubList;
}