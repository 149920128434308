.externalSupplyChainOperatorIdData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "supplyChainOperator    supplyChainOperator supplyChainOperator ."
            "type                   certificationBody   certificationBody   ."
            "externalId             externalId          .                   .";
}

.externalSupplyChainOperatorIdData .supplyChainOperator {
    grid-area: supplyChainOperator;
    --width-by-chars: 40;
}
