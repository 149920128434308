.organicMaterialPurchaseBasicData {
    grid-template-columns: min-content max-content max-content 1fr;
}

.organicMaterialPurchaseBasicData .buyer {
    grid-column: 1;
}

.organicMaterialPurchaseBasicData .certifiedOrganicMaterial {
    grid-column: 1 / 3;
}

.organicMaterialPurchaseBasicData .availableQuantity {
    grid-column: 1;
}

.organicMaterialPurchaseBasicData .initialQuantity {
    grid-column: 2;
}

.organicMaterialPurchaseBasicData .initialAvailableQuantity {
    grid-column: 3;
}

.organicMaterialPurchaseBasicData .gmoScreeningReport {
    grid-column: 1;
}

.organicMaterialPurchaseBasicData .screeningResult {
    grid-column: 1;
}

.organicMaterialPurchaseBasicData .rejected {
    grid-column: 2 / 5;
}

.organicMaterialPurchaseBasicData .organicMaterialOrigin {
    grid-column: 1 / 5;
}
