.dashboard {
    background-color: var(--dashboard-background-color);
    /* 138px */
    /* (2 *) padding + heading + 3(*1.2lineheight=3.6) *  content lines + actions + 2* row gap */
    --tile-height: calc(32px + var(--24pt) + (3.6 * var(--14pt)) + 1.5rem + (2 * var(--small-padding)));
}

.dashboard .dashboardSettingsButton {
    position: absolute;
    right: 0;
    top: 0;
}

.dashboard .dashboardGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(395px, 1fr));
    grid-gap: var(--layout-horizontal-spacing);
}

.dashboard .dashboardGrid .tile {
    display: grid;
    height: var(--tile-height);
    grid-template-areas:
            "icon title"
            "icon content"
            "actions actions";
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content minmax(min-content, 1fr) min-content;
    grid-column-gap: var(--layout-action-spacing);
    grid-row-gap: var(--small-padding);
    padding: 16px;
    word-break: break-word;
    cursor: move;
    overflow: auto;
}

.dashboard .dashboardGrid .tile span {
    line-height: 1;
}

.dashboard .dashboardGrid .tile .dashboard-icon-wrapper {
    grid-area: icon;
    align-self: flex-start;
}

.dashboard .dashboardGrid .tile .title-wrapper {
    font-size: var(--24pt);
    grid-area: title;
}

.dashboard .dashboardGrid .tile .content-wrapper {
    line-height: 1.2;
    font-weight: bold;
    justify-self: stretch;
    overflow: auto;
    white-space: break-spaces;
    grid-area: content;
    display: grid;
    grid-gap: .5ch 1ch;
    grid-template-columns: max-content auto;
    grid-auto-rows: min-content;
}

.dashboard .dashboardGrid .tile .content-wrapper [role="progressbar"] {
    grid-column: 1/-1;
}

.dashboard .dashboardGrid .tile .content-wrapper span:nth-of-type(odd) {
    justify-self: flex-end;
}

.dashboard .dashboardGrid .tile .content-wrapper span:nth-of-type(even) {
    font-weight: 400;
}

.dashboard .dashboardGrid .tile.previewTile .content-wrapper {
    font-size: var(--14pt);
    font-weight: normal;
    overflow: hidden;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.dashboard .dashboardGrid .tile .action-wrapper {
    grid-area: actions;
    justify-self: flex-end;
}

.dashboardGrid.dragOver .dropSpot, .dashboardGrid .dragging {
    border-radius: 5px;
    outline: 2px dashed var(--secondary-text-color);
}

.dashboardGrid.dragOver .dropSpot.dragOver, .dashboardGrid .dragging.dragOver {
    background: var(--primary-color);
}

.dashboardGrid .dropSpot, .dashboardGrid.dragOver .tile:last-of-type ~ .dropSpot {
    height: var(--tile-height);
}

.dashboardGrid .tile:last-of-type ~ .dropSpot {
    height: 0;
}

.dashboard .dashboardGrid .noBottomPadding {
    padding-bottom: 0;
}