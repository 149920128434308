.certificationLicenceBasicData {
    grid-template-columns: repeat(5, max-content) 1fr;
    grid-template-areas:
            "certificationBody  certificationBody   certificationBody   standard    licencingCode ."
            "startDate          endDate             .                   .           .           .";
}

.certificationLicenceBasicData .certificationBody {
    grid-area: certificationBody;
    --width-by-chars: 40;
}

.certificationLicenceBasicData .standard {
    grid-area: standard;
    --width-by-chars: 40;
}

.certificationLicenceBasicData .licencingCode {
    grid-area: licencingCode;
    --width-by-chars: 40;
}

.certificationLicenceBasicData .startDate {
    grid-area: startDate;
}

.certificationLicenceBasicData .endDate {
    grid-area: endDate;
}
