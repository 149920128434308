.userData {
    grid-template-columns: repeat(2, min-content) min-content 1fr;
    grid-template-areas:
            "username       role            blocked      blocked"
            "givenName      surname         surname      ."
            "organisation   standardBody    standardBody ."
            "email          email           email        ."
            "activation     activation      activation   activation";
}

.userData .username {
    grid-area: username;
    --width-by-chars: 40;
}

.userData .role {
    grid-area: role;
}

.userData .blocked {
    grid-area: blocked;
}

.userData .givenName {
    grid-area: givenName;
    --width-by-chars: 40;
}

.userData .surname {
    grid-area: surname;
    --width-by-chars: 40;
}

.userData .organisation {
    grid-area: organisation;
}

.userData .standardBody {
    grid-area: standardBody;
}

.userData .email {
    grid-area: email;
    --width-by-chars: 100;
}

.userData .activation-period {
    grid-area: activation;
    display: flex;
    gap: var(--layout-horizontal-spacing);
}
