.dashboard-icon-wrapper, .dashboard-icon-wrapper {
    border: 1px solid;
    border-radius: 100%;
    display: flex;
}

.dashboard-icon-wrapper .dashboardIcon {
    padding: 8px;
}

.dashboard-icon-wrapper.checkmarkIcon {
    color: var(--dashboard-icon-1-color);
    background-color: var(--dashboard-icon-1-background-color);
}

.dashboard-icon-wrapper.clockIcon,
.dashboard-icon-wrapper.percentIcon {
    color: var(--dashboard-icon-2-color);
    background-color: var(--dashboard-icon-2-background-color);
}

.dashboard-icon-wrapper.crossIcon,
.dashboard-icon-wrapper.warningIcon {
    color: var(--dashboard-icon-3-color);
    background-color: var(--dashboard-icon-3-background-color);
}

.dashboard-icon-wrapper.infoIcon,
.dashboard-icon-wrapper.notepadIcon,
.dashboard-icon-wrapper.bubbleIcon {
    color: var(--dashboard-icon-4-color);
    background-color: var(--dashboard-icon-4-background-color);
}
