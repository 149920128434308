.breadcrumbContainer {
    display: flex;
    color: var(--tertiary-text-color);
    --layout-horizontal-spacing: 8px;
    padding: 5px 0 10px;
}

.breadcrumbContainer ol {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--layout-horizontal-spacing));
}

.breadcrumbContainer li {
    margin-left: var(--layout-horizontal-spacing);
}

.breadcrumbContainer li .breadcrumb {
    margin-right: var(--layout-horizontal-spacing);
}

.breadcrumbContainer li .breadcrumb:hover {
    color: var(--secondary-text-color);
}

.breadcrumbContainer .breadcrumb {
    color: var(--tertiary-text-color);
    font-size: var(--16pt);
    padding: 5px 0;
    text-decoration: none;
}
