.dashboard .dashboardSettingsButton, .homepage .dashboardSettingsButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0;
    border-right: 0;
    color: var(--tertiary-text-color);
    background-color: var(--header-border-color);
    border-color: var(--header-border-color);
}
