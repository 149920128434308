header {
    height: var(--header-height);
    display: flex;
    border-bottom: 1px solid var(--header-border-color);
    background-color: var(--primary-background-color);
    padding-left: var(--horizontal-window-spacing);
}

.gtbLogoWrapper {
    /* 66px */
    height: 4.125rem;
    align-self: center;
    margin-right: auto;
}

.gtbLogoWrapper > img:first-child {
    /* 66px */
    height: 4.125rem;
    width: auto;
    align-self: center;
}

header > .headerLinks {
    margin: auto 130px auto auto;
}

header .standardLogoWrapper {
    /* 182px */
    width: 11.375rem;
    height: 100%;
    padding: 16px;
    border-left: 1px solid var(--header-border-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

header .standardLogoWrapper img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.headerLink {
    float: left;
    margin-left: 35px;
    font-size: var(--14pt);
    color: var(--primary-neutral-color);
}

.headerLink a {
    display: inline-flex;
    align-items: center;
    color: currentColor;
    text-decoration: none;
}

.headerLink button {
    display: inline-flex;
    align-items: center;
    color: currentColor;
    text-decoration: none;
    background: transparent;
    border: none;
    font-size: var(--14pt);
}

.headerLink svg {
    margin: 0 5px 0 7px;
}

.userAccountButtonContainer {
    margin: auto 50px auto 0;
}

.userAccountButtonContainer .dropdown .dropdownMenu .dropdownMenuItem:last-child > * {
    color: var(--highlight-color);
}

.userAccountButtonContainer .dropdownMenuItem a {
    display: inline-flex;
    align-items: center;
    color: currentColor;
    text-decoration: none;
}

.userAccountButtonContainer .dropdownMenuItem button {
    color: currentColor;
}

.userAccountButton {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 10px;
    align-items: center;
    padding: 0;
    color: var(--primary-text-color);
    border: none;
    background: transparent;
    text-align: start;
}

.userAccountButton .username {
    grid-column: 1;
    font-weight: bold;
    font-size: var(--16pt);
}

.userAccountButton .authorisationRole {
    grid-column: 1;
    font-size: var(--14pt);
}

.userAccountButton .environmentName {
    grid-column: 1;
    font-weight: bold;
    color: var(--warning-color);
    font-size: var(--14pt);
}

.userAccountButton svg {
    grid-column: 2;
    grid-row: 1 / span 3;
}

button.warningDropdownButton {
    color: var(--highlight-color);
}
