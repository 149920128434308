.supplyChainOperatorOtherData {
    grid-template-columns: repeat(4, min-content) 1fr;
}

.supplyChainOperatorOtherData .brandNames {
    grid-column: 1 / 5;
    --width-by-chars: 80;
}

.supplyChainOperatorOtherData .membership {
    grid-column: 1;
    --width-by-chars: 20;
}

.supplyChainOperatorOtherData .cbInternalId {
    grid-column: 1;
    --width-by-chars: 40;
}

.supplyChainOperatorOtherData .externalIds {
    grid-column: 1/6;
}
