.external-view {
    height: 100vh;
    min-height: 640px;
    overflow: auto;

    background-color: #F4F4F4;
    background-image: url("../assets/images/Login background image.svg");
    background-repeat: no-repeat;
    background-size: auto 76%;
    background-position-y: 70%;
    background-position-x: -39px;

    display: grid;
    grid-template-rows: 1fr min-content 2fr min-content 4fr;
    grid-template-columns: 1.25fr 1fr 1fr 1fr 1.25fr;
    grid-template-areas:
            ". . . . ."
            ". . img . ."
            ". . . . ."
            ". . form form ."
            ". . . . ."
            "footer footer footer footer footer";
}

.external-view span, .external-view p {
    color: var(--tertiary-text-color);
}

.external-view > img {
    /* 103,6px */
    height: 6.475rem;
    width: auto;
    grid-area: img;
}

.external-view .card {
    /* 396px */
    width: 24.75rem;
    grid-area: form;
    justify-self: flex-end;
}

.external-view .card .external-form-header {
    margin: 0 0 59px;
}

.external-view footer {
    grid-area: footer;
}
