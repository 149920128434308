.stateProvinceBasicData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
            "name       name        name    countryArea isoCode ."
            "latitude   longitude   .       .           .       ."
            "startDate  endDate     .       .           .       .";
}

.stateProvinceBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.stateProvinceBasicData .countryArea {
    grid-area: countryArea;
    --width-by-chars: 40
}

.stateProvinceBasicData .isoCode {
    grid-area: isoCode;
    /* for label */
    --width-by-chars: 7;
}

.stateProvinceBasicData .latitude {
    grid-area: latitude;
}

.stateProvinceBasicData .longitude {
    grid-area: longitude;
}

.stateProvinceBasicData .startDate {
    grid-area: startDate;
}

.stateProvinceBasicData .endDate {
    grid-area: endDate;
}
