.shipments {
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-template-areas:
        "certificationBody  .                   ."
        "seller             buyer               ."
        "shipmentSubList    shipmentSubList     shipmentSubList"
}

.shipments .certificationBody {
    grid-area: certificationBody;
}

.shipments .seller {
    grid-area: seller;
}

.shipments .buyer {
    grid-area: buyer;
}

.shipments .shipmentSubList {
    grid-area: shipmentSubList;
}
