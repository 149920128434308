.secretTokenInfo {
    margin-top: 0;
}

.secretTokenQrContainer {
    width: 100%;
    text-align: center;
}

.secretTokenQr {
    max-width: 256px;
    align-self: center;
}

.secretToken {
    text-align: center;
}

.confirmOtpField {
    text-align: center;
}

.confirmOtpField .gtb-textfield {
    width: 256px;
}
