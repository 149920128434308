.externalFacilityIdDetailView {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "facility   facility            facility            ."
            "type       certificationBody   certificationBody   ."
            "externalId externalId          .                   .";
}

.externalFacilityIdDetailView .facility {
    grid-area: facility;
    --width-by-chars: 40;
}
