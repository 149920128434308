.tabs {
    overflow: auto;
}

.tabs [role="tablist"] {
    display: flex;
    flex-direction: row;
    background-color: var(--secondary-background-color);
    white-space: nowrap;
    min-width: min-content;
}

.tabs [role="tablist"]:after {
    content: "";
    width: 100%;
    border-bottom: 1px solid var(--header-border-color);
}

.tabs [role="tabpanel"]:focus-visible {
    outline: none;
    box-shadow: var(--focus-shadow);
}

[role="tablist"] > li > [role="tab"], [role="tablist"] [aria-expanded] {
    height: var(--min-component-height);
    font-size: var(--18pt);
    font-weight: bold;
    text-decoration: none;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-neutral-color);
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--header-border-color);
}

[role="tablist"] [aria-expanded] svg {
    margin-left: 6px;
}

[role="tablist"] [role="tab"]:focus-visible {
    box-shadow: inset var(--focus-shadow);
}

[role="tablist"] > li > [role="tab"][aria-selected="true"], [role="tablist"] .dropdownWrapper [aria-current="true"] {
    color: var(--primary-color);
    background-color: var(--primary-background-color);
    border-left: 1px solid var(--header-border-color);
    border-right: 1px solid var(--header-border-color);
    border-bottom: 1px solid transparent;
}

[role="tablist"] li:first-child [role="tab"][aria-selected="true"] {
    border-left: none;
}

[role="tablist"] [role="tab"][aria-invalid="true"] {
    color: var(--error-color);
}

.tabs [role="tabpanel"] .dataGridContainer:first-child .gridWrapper {
    min-height: 400px;
}
