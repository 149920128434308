.processCategoryBasicData {
    grid-template-columns: repeat(3, max-content) 1fr;
    grid-template-areas:
            "code               code                 pickListSequence   pickListSequence"
            "name        name                       .                       ."
            "activation         activation              activation              .";
}

.processCategoryBasicData .code {
    grid-area: code;
    --width-by-chars: 6;
}

.processCategoryBasicData .pickListSequence {
    grid-area: pickListSequence;
    max-width: 19rem;
}

.processCategoryBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.processCategoryBasicData .activation-period {
    grid-area: activation;
    display: flex;
    gap: var(--layout-horizontal-spacing);
}

