.parameter-card {
    display: flex;
    flex-direction: column;
    min-height: 1px;
}

.parameter-card dl {
    flex-grow: 1;
    overflow: auto;
    display: grid;
    grid-template-columns: min-content auto;
    margin: 0;
}

.parameter-card dl dt {
    font-weight: bold;
    padding: var(--small-padding);
}

.parameter-card dl dd {
    font-family: monospace;
    font-size: var(--14pt);
    margin-left: 0;
    padding: var(--small-padding);
}

.parameter-card :is(dt:nth-of-type(odd), dd:nth-of-type(odd)) {
    background-color: var(--secondary-background-color);
}
