.scoLicenceBasicData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
            "supplyChainOperator    supplyChainOperator    supplyChainOperator     standard        licenceNumber   ."
            "startDate              endDate                .                       bannedUntil     .               .";
}

.scoLicenceBasicData .supplyChainOperator {
    grid-area: supplyChainOperator;
}

.scoLicenceBasicData .standard {
    grid-area: standard;
}

.scoLicenceBasicData .licenceNumber {
    grid-area: licenceNumber;
    --width-by-chars: 20;
}

.scoLicenceBasicData .startDate {
    grid-area: startDate;
}

.scoLicenceBasicData .endDate {
    grid-area: endDate;
}

.scoLicenceBasicData .bannedUntil {
    grid-area: bannedUntil;
}
