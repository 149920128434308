.helperText {
    margin: 3px 14px 0;
    font-size: 0.75rem;
    line-height: 1.66;
    white-space: pre-line;
}

.inputError .helperText {
    color: var(--highlight-color);
}
