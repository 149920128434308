.certificationBodyCertificationData {
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-template-areas:
            "authorisedPerson           authorisedPerson            ."
            "scTemplate                 tcTemplate                  ."
            "blockSize                  .                           .";
}

.certificationBodyCertificationData .authorisedPerson {
    grid-area: authorisedPerson;
    --width-by-chars: 80;
}

.certificationBodyCertificationData .scTemplate {
    grid-area: scTemplate;
}

.certificationBodyCertificationData .tcTemplate {
    grid-area: tcTemplate;
}

.certificationBodyCertificationData .blockSize {
    grid-area: blockSize;
}
