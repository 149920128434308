.scopeCertificateFacilityBasicData {
    grid-template-columns: repeat(4, min-content) 1fr;
    grid-template-areas:
        "supplyChainOperator    scProductNumber         .                       .                       ."
        "productCategory        productDetail           productDetail           labelGrade              ."
        "processCategory        facility                facility                facility                .";
}

.scopeCertificateFacilityBasicData .supplyChainOperator {
    grid-area: supplyChainOperator;
    --width-by-chars: 40;
}

.scopeCertificateFacilityBasicData .scProductNumber {
    grid-area: scProductNumber;
    --width-by-chars: 16;
}

.scopeCertificateFacilityBasicData .productCategory {
    grid-area: productCategory;
    --width-by-chars: 40;
}

.scopeCertificateFacilityBasicData .productDetail {
    grid-area: productDetail;
    --width-by-chars: 40;
}

.scopeCertificateFacilityBasicData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.scopeCertificateFacilityBasicData .processCategory {
    grid-area: processCategory;
    --width-by-chars: 40;
}

.scopeCertificateFacilityBasicData .facility {
    grid-area: facility;
    --width-by-chars: 80;
}
