.tcProductLogisticData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "productCategoryPrint   productDetailPrint      labelGrade              ."
            "orderNumber            articleNumber           productionDate          ."
            "processors             processors              lastProcessor           ."
            "additionalInformation  additionalInformation   .                       .";
}

.tcProductLogisticData .productCategoryPrint {
    grid-area: productCategoryPrint;
    --width-by-chars: 40;
}

.tcProductLogisticData .productDetailPrint {
    grid-area: productDetailPrint;
    --width-by-chars: 40;
}

.tcProductLogisticData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.tcProductLogisticData .orderNumber {
    grid-area: orderNumber;
    --width-by-chars: 60;
}

.tcProductLogisticData .articleNumber {
    grid-area: articleNumber;
}

.tcProductLogisticData .productionDate {
    grid-area: productionDate;
    min-width: 170px;
}

.tcProductLogisticData .processors {
    grid-area: processors;
}

.tcProductLogisticData .lastProcessor {
    grid-area: lastProcessor;
}

.tcProductLogisticData .additionalInformation {
    grid-area: additionalInformation;
}
