a[aria-disabled] svg {
    opacity: 0.3;
}

a:focus-visible {
    outline: none;
    box-shadow: var(--focus-shadow);
}

.secondaryLinkItem {
    /* 32px */
    height: 2rem;
    border: 1px solid var(--primary-color);
    font-size: var(--13pt);
    font-weight: bold;
    background-color: var(--primary-background-color);
    border-radius: 5px;
    color: var(--primary-color);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.secondaryLinkItem svg {
    margin-left: 8px;
}

.secondaryLinkItemSmall {
    /* 32px */
    height: 1.5rem;
    border: 1px solid var(--primary-color);
    font-size: var(--13pt);
    font-weight: bold;
    background-color: var(--primary-background-color);
    border-radius: 5px;
    color: var(--primary-color);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.secondaryLinkItemSmall svg {
    margin-left: 8px;
}
