.standardBasicData {
    grid-template-columns: min-content min-content min-content 1fr;
    grid-template-areas:
            "acronym officialName officialName officialName"
            "standardType standardType standardType standardType"
            "standardBody standardBody . ."
            "tcm tcm tcm tcm"
            "standardVersions standardVersions standardVersions standardVersions";
}

.standardBasicData .acronym {
    grid-area: acronym;
    --width-by-chars: 10;
}

.standardBasicData .officialName {
    grid-area: officialName;
    justify-self: flex-start;
    --width-by-chars: 80;
}

.standardBasicData .standardType {
    grid-area: standardType;
}

.standardBasicData .standardBody {
    grid-area: standardBody;
    --width-by-chars: 80;
}

.standardBasicData .transactionCertificateRequired {
    grid-area: tcm;
}

.standardBasicData .standardVersions {
    grid-area: standardVersions;
}
