.inputWrapper {
    position: relative;
}

.inputComponentWrapper {
    position: relative;
    overflow: hidden;
}

.inputComponentWrapper > * {
    min-height: var(--min-component-height);
    background: none;
}

.inputComponentFrame legend span {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    opacity: 0;
}

/* +++ Input Component NOT focused and without value +++ */

.inputComponentFrame {
    position: absolute;
    border: 1px solid var(--primary-neutral-color);
    border-radius: 4px;
    text-align: left;
    top: -5px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    overflow: hidden;
    min-width: 0;
}

.inputWrapperLabel {
    position: absolute;
    color: var(--primary-neutral-color);
    left: 0;
    top: 0;
    font-weight: 400;
    padding: 0;
    font-size: 1rem;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    z-index: 1;
    pointer-events: none;
    height: 3.125rem;
    display: inline;
    line-height: 3.125;
    -ms-transform: translate(14px, 0) scale(1);
    transform: translate(14px, 0) scale(1);
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.inputComponentFrame legend {
    font-size: 0.75em;
    visibility: hidden;
    max-width: 0;
    padding: 0;
    text-align: left;
    pointer-events: none;
    height: 11px;
    white-space: nowrap;
    -webkit-transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

/* +++ Input Component focused or with value +++ */

.inputComponentWrapper:not(.ignoreFocus):focus-within .inputComponentFrame {
    border-width: 2px;
}

.inputComponentWrapper:not(.ignoreFocus):focus-within ~ .inputWrapperLabel,
.inputComponentWrapper.hasValue ~ .inputWrapperLabel,
.inputComponentWrapper.labelShrink ~ .inputWrapperLabel {
    max-width: calc(133% - 24px);
    -ms-transform: translate(14px, calc(-50% + 0.375rem)) scale(0.75);
    transform: translate(14px, calc(-50% + 0.375rem)) scale(0.75);
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.inputComponentWrapper:not(.ignoreFocus):focus-within .inputComponentFrame legend,
.inputComponentWrapper.hasValue .inputComponentFrame legend,
.inputComponentWrapper.labelShrink .inputComponentFrame legend {
    max-width: 100%;
    -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
}

/* on hover/focussing input component */
.inputComponentWrapper:hover .inputComponentFrame, .inputComponentWrapper:not(.ignoreFocus):focus-within .inputComponentFrame {
    border-color: var(--primary-color);
}

/* only change color of label on focus */
.inputComponentWrapper:not(.ignoreFocus):focus-within ~ .inputWrapperLabel {
    color: var(--primary-color);
}

/* +++ read only +++ */

.inputWrapper.inputWrapperReadOnly .inputComponentWrapper {
    background-color: var(--secondary-background-color);
}


/* +++ error display +++ */

.inputWrapper.inputError .inputWrapperLabel {
    color: var(--highlight-color);
}

.inputWrapper.inputError .inputComponentWrapper {
    background-color: var(--error-background-color);
}

.inputWrapper.inputError .inputComponentFrame {
    border-color: var(--highlight-color);
}

.inputWrapper .inputWrapperTextField fieldset {
    border: 0;
    border-radius: 0;
}

.inputWrapper .inputWrapperEndButton {
    height: auto;
    border-radius: 0;
    border: 0;
    border-left: 1px solid;
}

.inputWrapper.inputError .inputWrapperEndButton {
    border-color: var(--highlight-color);
}
