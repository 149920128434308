.scopeCertificateProcessCategoryBasicData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
        "supplyChainOperator    scProductNumber         .                       .                       .               ."
        "productCategory        productDetail           productDetail           labelGrade              labelGrade      ."
        "processCategory        processCategoryPrint    processCategoryPrint    processCategoryPrint    .               ."
        "scFacilities           scFacilities            scFacilities            scFacilities            scFacilities    scFacilities";
}

.scopeCertificateProcessCategoryBasicData .supplyChainOperator {
    grid-area: supplyChainOperator;
    --width-by-chars: 40;
}

.scopeCertificateProcessCategoryBasicData .scProductNumber {
    grid-area: scProductNumber;
    --width-by-chars: 16;
}

.scopeCertificateProcessCategoryBasicData .productCategory {
    grid-area: productCategory;
    --width-by-chars: 40;
}

.scopeCertificateProcessCategoryBasicData .productDetail {
    grid-area: productDetail;
    --width-by-chars: 40;
}

.scopeCertificateProcessCategoryBasicData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.scopeCertificateProcessCategoryBasicData .processCategory {
    grid-area: processCategory;
    --width-by-chars: 40;
}

.scopeCertificateProcessCategoryBasicData .processCategoryPrint {
    grid-area: processCategoryPrint;
    --width-by-chars: 50;
}

.scopeCertificateProcessCategoryBasicData .scFacilities {
    grid-area: scFacilities;
}
