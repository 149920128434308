.radiobuttonGroupWrapper {
    display: flex;
}

.radiobuttonGroupWrapper .spinner {
    margin: auto 0 auto var(--layout-horizontal-spacing);
}

:is(.horizontalRadiobuttonGroup, .verticalRadiobuttonGroup) {
    display: grid;
    align-items: center;
}

.horizontalRadiobuttonGroup {
    grid-template-columns: auto auto;
    grid-column-gap: var(--layout-horizontal-spacing);
}

.horizontalRadiobuttonGroup .accessibleLabel::after {
    content: ":";
}

.verticalRadiobuttonGroup .accessibleLabel {
    display: block;
    margin-bottom: var(--layout-horizontal-spacing);
}

.verticalRadiobuttonGroup [role="radiogroup"] {
    margin-left: var(--layout-horizontal-spacing);
}

.inputError:is(.horizontalRadiobuttonGroup, .verticalRadiobuttonGroup) .accessibleLabel {
    color: var(--highlight-color);
}

.horizontalRadiobuttonGroup .helperText {
    grid-column: 1 / 3;
}
