.labelGradeBasicData {
    grid-template-columns: repeat(5, max-content) 1fr;
    grid-template-areas:
            "name                   name                    standardVersion         typeOfMaterial          minPercentage           ."
            "materialContentRules   materialContentRules    materialContentRules    materialContentRules    materialContentRules    materialContentRules";
}

.labelGradeBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.labelGradeBasicData .standardVersion {
    grid-area: standardVersion;
}

.labelGradeBasicData .materialContentRules {
    grid-area: materialContentRules;
}