.authorisationRoleBasicData {
    grid-template-columns: min-content min-content auto 1fr;
    grid-template-areas:
            "name                   level                   .                       ."
            "type                   type                    type                    ."
            "canReduceOtpPrompting  canReduceOtpPrompting   canReduceOtpPrompting   ."
            "authorisationTypes authorisationTypes authorisationTypes   authorisationTypes"
}

.authorisationRoleBasicData .name {
    grid-area: name;
    --width-by-chars: 20;
}

.authorisationRoleBasicData .type {
    grid-area: type;
}

.authorisationRoleBasicData .level {
    grid-area: level;
    --width-by-chars: 10; /*  for label inside field  */
}

.authorisationRoleBasicData .canReduceOtpPrompting {
    grid-area: canReduceOtpPrompting;
}

.authorisationTypes {
    grid-area: authorisationTypes;
    margin-top: calc(-1 * var(--layout-vertical-spacing));
}
