.certificate_meta_data {
    font-size: var(--14pt);
}

.tls_certificate_upload {
    max-width: 40rem;
}

.tls_error_list {
    list-style: revert;
    padding: revert;
}