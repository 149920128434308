.tcProducts {
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-template-areas:
        "certificationBody  .                   ."
        "seller             buyer               ."
        "tcProductSubList   tcProductSubList    tcProductSubList"
}

.tcProducts .certificationBody {
    grid-area: certificationBody;
}

.tcProducts .seller {
    grid-area: seller;
}

.tcProducts .buyer {
    grid-area: buyer;
}

.tcProducts .tcProductSubList {
    grid-area: tcProductSubList;
}
