.accept-terms-of-use-dialog-content {
    align-items: center;
    margin-bottom: 35px;
}


.terms-of-use-dialog {
    width: 900px;
    height: 80vh;
}

.terms-of-use-dialog > .dialog-footer {
    display: grid;
    grid-template-areas:
            "terms-of-use-accepted-checkbox     terms-of-use-accepted-checkbox"
            "terms-of-use-ok                    terms-of-use-cancel           ";
}

.terms-of-use-accepted-checkbox {
    grid-area: terms-of-use-accepted-checkbox;
    align-items: center;
    font-size: var(--18pt);
    margin-bottom: 25px;
}

.terms-of-use-accepted-checkbox .checkbox-styled {
    width: 22px;
    height: 22px;
}

.terms-of-use-ok {
    grid-area: terms-of-use-ok;
}

.terms-of-use-cancel {
    grid-area: terms-of-use-cancel;
}
