.dropContainer {
    --drop-zone-height: 10px;
    --drop-zone-line-height: 4px;
}

.dropContainer.dragOver {
    background: var(--secondary-background-color);
    outline: 2px dashed var(--secondary-text-color);
}

.dropContainer.dragOver > .dragWrapper:not(.dragging) > * {
    transform: scale(0.95);
}

.dropContainer .dragWrapper {
    cursor: move;
}

.dropContainer .dragWrapper:first-child {
    height: 16px;
    cursor: default;
}

.dropContainer > .dragWrapper, .dropContainer > .dragWrapper > * {
    transition: transform var(--animation-speed) ease-in-out;
}

.dropContainer > .dragWrapper.dragging > * {
    transform: scale(0.85);

}

.dropContainer > .dragWrapper:after {
    content: "";
    display: block;
    margin: calc((var(--drop-zone-height) - var(--drop-zone-line-height)) / 2) 0;
    height: var(--drop-zone-line-height);
    width: 100%;
}

.dropContainer > .dragWrapper.dragOver:after,
.dropContainer > .dragWrapper.dragging.dragOver,
.dropContainer > .dragWrapper.dragging:after,
.dropContainer > .dragWrapper.dragOver + .dragWrapper.dragging,
.dropContainer:not(.dragOver) > .dragWrapper.dragging {
    background: var(--primary-color);
}

.dropContainer > .dragWrapper.dragging:after, .dropContainer > .dragWrapper + .dragWrapper.dragging {
    margin-top: calc(-1 * var(--drop-zone-height));
}

.dropContainer > .dragWrapper.dragging:after {
    content: none;
}
