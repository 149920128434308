.addOrganicMaterialProductionDialog {
    display: flex;
    flex-direction: column;
}

.addOrganicMaterialProductionDialogMessage {
    padding: 10px var(--dialog-horizontal-spacing) 20px var(--dialog-horizontal-spacing);
    font-size: var(--18pt);
    color: var(--primary-text-color);
    width: 1000px;
}