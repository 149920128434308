.noMargin {
    margin: 0 !important;
}

.noPadding {
    padding: 0 !important;
}

.mainLayout {
    height: 100vh;
    min-height: 400px;

    display: grid;
    grid-template-rows: min-content min-content 1fr min-content;
    grid-template-areas:
            "banner"
            "mainMenu"
            "main"
            "footer";
}

.mainLayout header {
    grid-area: banner;
}

.mainLayout .mainMenu {
    grid-area: mainMenu;
}

.mainLayout main {
    grid-area: main;

    width: 100%;
    min-height: 1px;
    overflow: hidden;
    position: relative;
}

.mainLayout footer {
    grid-area: footer;
}

@keyframes slideInFromTop {
    from {
        transform: scaleY(0.01);
        opacity: .5;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(50%) scaleX(0);
    }
    to {
        transform: translateX(0%) scaleX(1);
    }
}
