.countryAreaAdditionalData {
    grid-template-columns: min-content min-content 1fr;
    grid-template-areas:
            "addressPattern .               ."
            "welcomeMessage welcomeMessage  .";
    min-height: 100% !important;
}

.countryAreaAdditionalData .addressPattern {
    grid-area: addressPattern;
    --width-by-chars: 40;
}

.countryAreaAdditionalData .welcomeMessage {
    grid-area: welcomeMessage;
    --width-by-chars: 80;
}
