.facilityOtherData {
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-template-areas:
        "numberOfEmployees  .               ."
        "cbInternalId       cbInternalId    ."
        "externalIds        externalIds     externalIds";
}

.facilityOtherData .numberOfEmployees {
    grid-area: numberOfEmployees;
    --width-by-chars: 20;
}

.facilityOtherData .cbInternalId {
    grid-area: cbInternalId;
    --width-by-chars: 40;
}

.facilityOtherData .externalIds {
    grid-area: externalIds;
}
