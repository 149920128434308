.gtb-textfield input,
.MuiFormControl-root.MuiTextField-root.gtb-textfield input {
    min-width: calc(var(--width-of-n) * var(--width-by-chars) + var(--textfield-horizontal-padding))
}

.gtb-textfield.gtb-textarea {
    --width-by-chars: 80;
    font-size: 1rem;
    min-width: calc(var(--width-of-n) * var(--width-by-chars) + (2 * var(--textfield-horizontal-padding)));
}

.MuiTextField-root input {
    /* 50px */
    height: 3.125rem;
    padding: 0 0 0 var(--textfield-horizontal-padding);
    color: var(--primary-text-color);
}

.MuiTextField-root label {
    color: var(--primary-neutral-color);
    height: 3.125rem;
    font-family: Arial, sans-serif;
    line-height: 3.125;
    display: inline;
    text-align: center;
    -ms-transform: translate(14px, 0) scale(1);
    transform: translate(14px, 0) scale(1);
}

.MuiTextField-root .MuiInputBase-root, .MuiTextField-root .MuiTypography-root {
    font-family: Arial, sans-serif;
    line-height: normal;
}

.MuiTextField-root label[data-shrink="true"] {
    -ms-transform: translate(14px, calc(-50% + 0.375rem)) scale(0.75);
    transform: translate(14px, calc(-50% + 0.375rem)) scale(0.75);
}

.MuiTextField-root label.Mui-focused {
    color: var(--primary-color);
}

.MuiTextField-root .MuiOutlinedInput-root fieldset {
    border-color: var(--primary-neutral-color);
}

.MuiTextField-root .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--primary-color);
}

.MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--primary-color);
    outline: 0;
}

.MuiTextField-root label.Mui-error {
    color: var(--highlight-color);
}

.gtb-textfield .MuiOutlinedInput-root.Mui-error {
    background-color: var(--error-background-color);
}

.MuiTextField-root .MuiOutlinedInput-root.Mui-error fieldset {
    border-color: var(--highlight-color);
}

.MuiTextField-root input:read-only, .MuiTextField-root textarea:read-only {
    background-color: var(--secondary-background-color);
    border-color: var(--primary-neutral-color);
    color: var(--tertiary-text-color);
}

.gtb-textfield.textfield-readOnly .MuiOutlinedInput-root {
    background-color: var(--secondary-background-color);
}
