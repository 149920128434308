.columnSettingsDrawer .drawerCard {
    flex-direction: column;
    gap: var(--small-padding);
}

.columnSettingsDrawer .drawerCard .toggle-container {
    align-self: stretch;
    align-items: flex-end;
    display: flex;
}

.columnSettingsDrawer .drawerCard .toggle-container .relativeContentWrapper {
    flex-grow: 1;
}

.columnSettingsDrawer .widthWrapper {
    align-self: flex-start;
    font-size: var(--14pt);
    display: flex;
    gap: var(--small-padding);
    align-items: center;
    margin-bottom: 3px;
}

.columnSettingsDrawer .widthWrapper label {
    height: 1em;
}

.columnSettingsDrawer .drawerCard .gtb-numberfield input {
    height: revert;
    width: 3rem;
    text-align: center;
    padding: 0;
}
