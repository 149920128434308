.help-layout {
    height: 100vh;
    min-height: 400px;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
            "banner"
            "main"
            "footer";
}

.help-layout header {
    grid-area: banner;
}

.help-layout header .environment-name {
    padding-right: 50px;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    color: var(--warning-color);
    font-size: var(--14pt);
}

.help-layout footer {
    grid-area: footer;
}

.help-grid {
    display: grid;
    grid-template-areas:
            "help-heading       help-content"
            "help-navigation    help-content";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-area: main;

    width: 100%;
    min-height: 1px;
    overflow: hidden;
    position: relative;
}

h1.help-heading.viewHeading {
    grid-area: help-heading;
    color: var(--primary-color);
    font-size: var(--50pt);
    border-bottom: 1px solid var(--header-border-color);
    margin: 0;
    padding: 40px 40px 25px 40px;
}

.help-title {
    font-weight: normal;
    font-size: var(--32pt);
    margin-bottom: 20px;
}

.help-content {
    grid-area: help-content;
    font-size: var(--16pt);
    justify-self: stretch;
    overflow: auto;
    padding-left: 40px;
    border-left: 1px solid var(--header-border-color);
}

.help-content .markdown-container {
    max-width: 820px;
}

/* So that help page title and first child in container start on same height */
.help-content .markdown-container > :first-child {
    margin-top: 40px;
    padding-top: 0;
}

.help-page-error-title {
    font-size: var(--20pt);
    margin-bottom: 16px;
}

.help-navigation {
    grid-area: help-navigation;
    color: var(--primary-color);
    font-size: var(--20pt);

    overflow: auto;

    padding: 0 1rem 0 40px;
    width: 480px
}

.help-navigation .help-navigation-item {
    margin: 1rem 0 1rem 0;
}

.help-navigation .help-navigation-link {
    color: var(--primary-color);
    text-decoration: none;
}

.help-navigation .help-navigation-link-selected {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
}

.help-navigation .icon-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.selected-indicator-icon path {
    stroke-width: 5px
}

.activeHelpPageIcon-left {
    margin-right: 0.3rem;
}

.activeHelpPageIcon-right {
    margin-left: 0.3rem;
}
