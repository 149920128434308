.multiValueTextField {
    width: 100%;
}

.multiValueTextField fieldset {
    border: 0;
    border-radius: 0;
}

.multiValueContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    padding: var(--textfield-horizontal-padding);
}

.multiValueTextField:focus-within.withValue fieldset {
    border-bottom: 2px solid var(--primary-color);
}

.multiValueTextField:focus-within ~ fieldset {
    border: 2px solid var(--primary-color);
}

.multiValueTextField.withValue fieldset {
    border-bottom: 1px solid var(--primary-neutral-color);
}
