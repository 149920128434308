:root {

    /* spacing */
    --horizontal-window-spacing: 140px;
    --layout-padding: 30px;
    --layout-vertical-spacing: 30px;
    --layout-horizontal-spacing: 15px;
    --layout-action-spacing: 25px;
    --layout-action-spacing-small: 15px;
    --textfield-horizontal-padding: 14px;
    --small-padding: 8px;

    /* px conversion - For accessibility reasons rem should be used for all font sizes and all containers which should grow depending on the font size*/
    --12pt: 0.750rem;
    --13pt: 0.8125rem;
    --14pt: 0.875rem;
    --16pt: 1rem;
    --18pt: 1.125rem;
    --20pt: 1.25rem;
    --24pt: 1.5rem;
    --32pt: 2rem;
    --50pt: 3.125rem;

    /* sizing */
    /* 50px */
    --min-component-height: 3.125rem;
    /* for textfield width by characters */
    --width-of-n: calc(0.85em * 27 / 40);
    --datepicker-width: 9.5rem;
    /* 110px */
    --header-height: 6.875rem;
    /* 70px */
    --footer-height: 4.375rem;
    /* 70px */
    --mainMenuHeight: 4.375rem;

    /* shadows */
    --shadow-color: #00000029;
    --focus-shadow: 0 0 2px 1px var(--primary-color);
    --layout-highlight-box-shadow: 0 0 8px var(--shadow-color);
    --dialog-shadow: 0 3px 6px var(--shadow-color);

    /* animation options */
    --animation-speed: 200ms;

    /* border */
    --component-border-radius: 5px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, input, button {
    font-family: Arial, sans-serif;
}

ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.innerGrid, .formGrid {
    display: grid;
    grid-column-gap: var(--layout-horizontal-spacing);
    grid-row-gap: var(--layout-vertical-spacing);
}

.formGrid {
    display: grid;
    grid-column-gap: var(--layout-horizontal-spacing);
    grid-row-gap: var(--layout-vertical-spacing);
    padding: var(--layout-padding);
    overflow: auto;
}

.formGrid .checkbox-label-container {
    align-self: center;
}

.flexRow {
    display: flex;
    align-items: center;
    margin-left: calc(-1 * var(--layout-horizontal-spacing));
}

.flexRow > * {
    margin-left: var(--layout-horizontal-spacing);
}

.flexColumn {
    display: flex;
    flex-direction: column;
    margin-top: calc(-1 * var(--layout-horizontal-spacing));
}

.flexColumn > * {
    margin-top: var(--layout-horizontal-spacing);
}

a {
    color: var(--primary-color);
}

.visuallyHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
