.organisationBasicData .businessName {
    grid-area: businessName;
    --width-by-chars: 40;
}

.organisationBasicData .website {
    grid-area: website;
    /* instead of 250 because of arrangement requirements */
    --width-by-chars: 40;
}

.organisationBasicData .organisationalEmail {
    grid-area: organisationalEmail;
    /* instead of 100 because of arrangement requirements */
    --width-by-chars: 60;
}

.organisationBasicData .organisationalPhoneNumber {
    grid-area: organisationalPhoneNumber;
    /* instead of 20 because of label width */
    --width-by-chars: 24;
}

.organisationBasicData .status {
    grid-area: status;
    --width-by-chars: 20;
}

.organisationBasicData .startDate {
    grid-area: startDate;
}

.organisationBasicData .endDate {
    grid-area: endDate;
}
