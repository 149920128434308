.supplyChainOperatorNewRelation {
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-template-areas:
            "relationType   relationType    ."
            "standard       .               ."
            "organisation   .               .";
}

.supplyChainOperatorNewRelation .relationType {
    grid-area: relationType;
}

.supplyChainOperatorNewRelation .standard {
    grid-area: standard;
}

.supplyChainOperatorNewRelation .certificationBody {
    grid-area: certificationBody;
}

.supplyChainOperatorNewRelation .organisation {
    grid-area: organisation;
}