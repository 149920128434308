.materialContentRuleBasicData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "standardVersion    labelGrade          minPercentage       ."
            "certifiedMaterial  certifiedMaterial   .                   ."
            "additionalMaterial additionalMaterial  additionalMaterial  additionalMaterial";
}

.materialContentRuleBasicData .standardVersion {
    grid-area: standardVersion;
    --width-by-chars: 40;
}

.materialContentRuleBasicData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.materialContentRuleBasicData .certifiedMaterial {
    grid-area: certifiedMaterial;
    --width-by-chars: 80;
}

.materialContentRuleBasicData .additionalMaterial {
    grid-area: additionalMaterial;
}

.materialContentRuleBasicData .minPercentage {
    grid-area: minPercentage;
    --width-by-chars: 15;
}