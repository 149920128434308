.detailViewInterface {
    --_maxHeight: calc(100svh - var(--header-height) - var(--footer-height) - var(--mainMenuHeight));
    position: sticky;
    height: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: auto;
    margin-right: calc(-1 * var(--horizontal-window-spacing));
    pointer-events: none;
    order: -1;
    z-index: 99;
}

.detailViewInterface > * {
    max-height: var(--_maxHeight);
    pointer-events: auto;
}

.detailViewComments {
    animation: slideInFromRight var(--animation-speed) linear;
    width: 350px;
    border-radius: 0 0 var(--component-border-radius) var(--component-border-radius);
}

.detailViewInterfaceButtons {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--header-border-color);
    border-bottom: 1px solid var(--header-border-color);
    border-bottom-left-radius: var(--component-border-radius);
    background-color: var(--secondary-background-color);
    overflow: auto;
}

.detailViewInterfaceButtons :is(button, a) {
    color: var(--list-column-header-color);
    border: none;
    background-color: transparent;
    padding: 20px;
}

.detailViewInterfaceButtons :is(button, a) svg {
    overflow: visible;
}

.detailViewInterfaceButtons button[aria-expanded=true] {
    transition: color var(--animation-speed) linear, background-color var(--animation-speed) linear;
    color: var(--secondary-background-color);
    background-color: var(--list-column-header-color);
}
