.organicMaterialOriginBasicData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
        "buyer                                  buyer                                   certifiedOrganicMaterial                ."
        "organicMaterialProducer                organicMaterialProducer                 organicMaterialProduction               ."
        "transferredQuantity                    .                                       .                                       ."
        "organicMaterialTransactionCertificate  organicMaterialTransactionCertificate   organicMaterialTransactionCertificate   organicMaterialTransactionCertificate";
}

.organicMaterialOriginBasicData .buyer {
    grid-area: buyer;
    --width-by-chars: 40;
}

.organicMaterialOriginBasicData .certifiedOrganicMaterial {
    grid-area: certifiedOrganicMaterial;
    --width-by-chars: 40;
}

.organicMaterialOriginBasicData .organicMaterialProducer {
    grid-area: organicMaterialProducer;
}

.organicMaterialOriginBasicData .organicMaterialProduction {
    grid-area: organicMaterialProduction;
}

.organicMaterialOriginBasicData .transferredQuantity {
    grid-area: transferredQuantity;
    --width-by-chars: 20;
}

.organicMaterialOriginBasicData .organicMaterialTransactionCertificate {
    grid-area: organicMaterialTransactionCertificate;
}