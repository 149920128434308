.dialog {
    --dialog-horizontal-spacing: 35px;
    position: relative;
    max-width: 90vw;
    min-width: min(674px, 90vw);
    border-radius: 5px;
    background-color: white;
    box-shadow: var(--dialog-shadow);
    overflow: hidden;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.formDialog {
    display: flex;
}

.dialog-close-button {
    position: absolute;
    top: 20px;
    right: 16px;
}

.dialog-close-button svg {
    color: var(--primary-neutral-color);
}

.dialog-header {
    padding: 20px var(--dialog-horizontal-spacing);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--header-border-color);
}

.dialog-header h2 {
    margin: 0;
}

.dialog-header > svg {
    margin-right: 15px;
}

.info-dialog-header {
    background-color: var(--info-background-color);
}

.info-dialog-header > svg {
    color: var(--info-color);
    width: 38px;
    height: 38px;
}

.warning-dialog-header {
    background-color: var(--warning-background-color);
}

.warning-dialog-header > svg {
    color: var(--warning-color);
    width: 39px;
    height: 35px;
}

.error-dialog-header {
    background-color: var(--error-background-color);
}

.error-dialog-header > svg {
    color: var(--error-color);
    width: 38px;
    height: 38px;
}

.dialog-content {
    padding: 40px var(--dialog-horizontal-spacing);
    align-self: stretch;
    white-space: pre-wrap;
    font-size: var(--18pt);
    color: var(--primary-text-color);
    overflow: auto;
    flex: 1 auto;
}

p.dialog-content {
    margin: 0;
    width: 674px;
}

.dialog-footer {
    padding: 0 var(--dialog-horizontal-spacing) 30px;
    display: flex;
    margin-left: -15px;
}

.dialog-footer > * {
    flex: 1 1;
    margin-left: 15px;
}
