.history-dialog-header-buttons {
    display: flex;
    justify-content: right;
    width: 100%;
}

.history-dialog-header-buttons svg {
    color: var(--primary-neutral-color);
}

.history-dialog-header-buttons .history-dialog-download-button {
    color: var(--primary-neutral-color);
    border-color: var(--primary-neutral-color);
    margin-right: 16px;
}

.history-dialog-changed-field {
    color: var(--highlight-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(80.0rem / 1.76);
}

.history-dialog-unchanged-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(80.0rem / 1.76);
}

.history-dialog {
    width: 80%;
}

.history-dialog td {
    white-space: nowrap;
}

.history-dialog th {
    text-align: left;
}

.history-dialog .dialog-content {
    padding: 0;
}

.history-dialog .dialog-content .gridWrapper {
    overflow: visible;
    border: none;
    box-shadow: none;
}
