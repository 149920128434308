.card {
    background: white;
    border-radius: 5px;
    padding: var(--layout-padding);
    box-shadow: var(--layout-highlight-box-shadow);
}

.cardHeader {
    margin: calc(-1 * var(--layout-padding)) calc(-1 * var(--layout-padding)) var(--layout-padding) calc(-1 * var(--layout-padding));
    position: relative; /* otherwise the box-shadow gets overlapped by the next components */
    padding: 15px var(--layout-padding);
    box-shadow: var(--dialog-shadow);
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas:
            "title content"
            "subtitle content";
    min-height: 5rem;
}

.card.noPadding .cardHeader {
    margin: 0;
}

.cardHeader .cardHeading {
    grid-area: title;
    margin: 0 0 5px 0;
    color: var(--primary-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardHeader .cardSubtitle {
    --_subtitleAdditionalHeight: 1rem;
    grid-area: subtitle;
    margin: calc(-1 * var(--_subtitleAdditionalHeight)) 0 0;
    color: var(--tertiary-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: var(--_subtitleAdditionalHeight);
}

.cardHeader .cardHeaderContent {
    grid-area: content;
    align-self: center;
}
