.externalIdDetailView .type {
    grid-area: type;
}

.externalIdDetailView .certificationBody {
    grid-area: certificationBody;
    --width-by-chars: 40;
}

.externalIdDetailView .externalId {
    grid-area: externalId;
    --width-by-chars: 40;
}