.preGtbTcMaterialBasicData {
    grid-template-columns: repeat(3, min-content);
}

.preGtbTcMaterialBasicData.withoutDynamicFields {
    grid-template-areas:
        "preGtbTcNumber             preGtbTcProductNumber   ."
        "preGtbTcProductCategory    preGtbTcProductDetail   labelGrade"
        "rawMaterial                certified               ."
        "percentage                 certifiedWeight         .";
}

.preGtbTcMaterialBasicData.withRawMaterialPrintAndOrigin {
    grid-template-areas:
        "preGtbTcNumber             preGtbTcProductNumber   ."
        "preGtbTcProductCategory    preGtbTcProductDetail   labelGrade"
        "rawMaterial                rawMaterialPrint        certified"
        "percentage                 certifiedWeight         ."
        "origin                     origin                  .";
}

.preGtbTcMaterialBasicData.withOrigin {
    grid-template-areas:
        "preGtbTcNumber             preGtbTcProductNumber   ."
        "preGtbTcProductCategory    preGtbTcProductDetail   labelGrade"
        "rawMaterial                certified               ."
        "percentage                 certifiedWeight         ."
        "origin                     origin                  .";
}

.preGtbTcMaterialBasicData.withRawMaterialPrint {
    grid-template-areas:
        "preGtbTcNumber             preGtbTcProductNumber   ."
        "preGtbTcProductCategory    preGtbTcProductDetail   labelGrade"
        "rawMaterial                rawMaterialPrint        certified"
        "percentage                 certifiedWeight         .";
}

.preGtbTcMaterialBasicData .preGtbTcNumber {
    grid-area: preGtbTcNumber;
    --width-by-chars: 30;
}

.preGtbTcMaterialBasicData .preGtbTcProductNumber {
    grid-area: preGtbTcProductNumber;
    --width-by-chars: 40;
}

.preGtbTcMaterialBasicData .preGtbTcProductCategory {
    grid-area: preGtbTcProductCategory;
    --width-by-chars: 50;
}

.preGtbTcMaterialBasicData .preGtbTcProductDetail {
    grid-area: preGtbTcProductDetail;
    --width-by-chars: 50;
}

.preGtbTcMaterialBasicData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 30;
}

.preGtbTcMaterialBasicData .rawMaterial {
    grid-area: rawMaterial;
    --width-by-chars: 30;
}

.preGtbTcMaterialBasicData .rawMaterialPrint {
    grid-area: rawMaterialPrint;
    --width-by-chars: 30;
}

.preGtbTcMaterialBasicData .certified {
    grid-area: certified;
    --width-by-chars: 30;
}

.preGtbTcMaterialBasicData .percentage {
    grid-area: percentage;
    --width-by-chars: 30;
}

.preGtbTcMaterialBasicData .certifiedWeight {
    grid-area: certifiedWeight;
    --width-by-chars: 20;
}

.preGtbTcMaterialBasicData .origin {
    grid-area: origin;
    --width-by-chars: 30;
}