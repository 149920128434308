.drawer {
    --drawer-horizontal-spacing: 35px;
    height: 100vh;
    margin-left: auto;
    background: var(--primary-background-color);
    box-shadow: -3px 3px 6px var(--shadow-color);
    display: flex;
    flex-direction: column;
}

.drawer .drawerHeading {
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: -3px 3px 6px var(--shadow-color);
}

.drawer .drawerHeading #drawerTitle {
    margin: 20px auto 20px var(--drawer-horizontal-spacing);
    color: var(--primary-text-color);
    font-weight: 400;
}

.drawer .drawerHeading .drawer-close-button {
    margin: auto var(--layout-horizontal-spacing);
    color: var(--primary-neutral-color);
}

.drawer .drawerContainer {
    padding: var(--layout-vertical-spacing) var(--drawer-horizontal-spacing);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--small-padding)
}

.drawer .drawerContainer > button {
    align-self: flex-start;
}

.drawer .drawerContainer > * {
    max-width: calc(100vw - 2 * var(--drawer-horizontal-spacing));
    flex-shrink: 0;
}

.drawer .drawerCard {
    background: var(--primary-background-color);
    border-radius: var(--component-border-radius);
    border: 1px solid var(--header-border-color);
    font-size: var(--24pt);
    color: var(--primary-text-color);
    padding: 0 var(--layout-horizontal-spacing);
    min-height: 90px;
    gap: var(--layout-horizontal-spacing);
    display: flex;
    align-items: center;
}
