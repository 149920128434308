.dataGridContainer {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 1px;
    max-width: 100%;
}

.dataGridContainer .deleteButton {
    color: var(--highlight-color);
}

.gridWrapper {
    min-height: 200px;
    max-height: 100%;
    border: 1px solid var(--header-border-color);
    border-radius: 5px;
    box-shadow: var(--layout-highlight-box-shadow);
    overflow: auto;
}

.gridActionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: calc(var(--layout-action-spacing) * -1);
    margin-bottom: var(--layout-action-spacing-small);
}

.gridActionContainer > * {
    margin-left: var(--layout-action-spacing);
}

.gridActionContainer .dropdownWrapper > button {
    border: 1px solid var(--primary-neutral-color);
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color);
    border-radius: var(--component-border-radius);
    padding: 0 12px;
}

.gridActionContainer .dropdownWrapper > button svg {
    margin-left: var(--layout-action-spacing);
}


.gridControlSpacer {
    margin-left: auto;
}

.gridItemCount {
    font-size: var(--14pt);
}

.gridWrapper .numberColumn, .gridWrapper .numberColumn .headerTitle {
    text-align: end;
}

.gridWrapper .numberColumn input[type="text"] {
    text-align: end;
    padding-right: 16px;
}

.gridWrapper tbody .numberColumn {
    padding-right: 36px;
}

table {
    min-width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
    --grid-row-vertical-spacing: var(--13pt);
}

table thead th {
    position: sticky;
    top: 0;
    padding: 16px 20px;
    white-space: nowrap;
    background-color: var(--secondary-background-color);
    vertical-align: top;
    z-index: 10;
}

table thead th .headerCell {
    display: grid;
    grid-template-areas: "title" "filterComponent";
    grid-template-columns: 1fr;
    row-gap: 13px;
}

table thead th .headerCell .headerTitle {
    display: block;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
}

table thead th .headerCell .gridFilterAll {
    grid-row: 2;
}

table thead th .resizeHandle {
    position: absolute;
    cursor: col-resize;
    user-select: none;
    top: 0;
    right: 0;
    height: 100%;
    width: 5px;
}

table thead th:hover .resizeHandle {
    border-right: 1px solid var(--header-border-color);
    border-left: 1px solid var(--header-border-color);
}

table thead th .resizeHandle:hover, table thead th.resizing .resizeHandle {
    border-right: 2px solid var(--primary-color);
    border-left: 2px solid var(--primary-color);
}

table thead th[aria-sort="descending"] .headerCell .headerTitle svg {
    rotate: 180deg;
}

table thead th .headerCell .headerTitle svg {
    transition: rotate var(--animation-speed);
    opacity: 0;
}

table thead th .headerTitle:not(:disabled):is(:hover, :focus-visible) svg {
    opacity: .5;
}

table thead th[aria-sort] .headerTitle svg {
    opacity: 1;
}

table thead th .headerTitle {
    grid-area: title;
    justify-self: flex-start;
    color: var(--list-column-header-color);
    font-weight: bold;
    display: flex;
    align-items: center;
}

table thead th .gridFilter {
    grid-area: filterComponent;
}

table thead th .headerTitle:not(:disabled):hover, table thead th[aria-sort] .headerTitle {
    color: var(--list-column-header-highlight-color);
}

table tbody td {
    padding: var(--grid-row-vertical-spacing) 20px;
    word-break: break-word;
    position: relative; /* needed for context menu */
}

table th .MuiCheckbox-root, table td .MuiCheckbox-root {
    padding: 0;
    justify-content: left;
}

table .listItemSelection {
    --primary-neutral-color: var(--list-item-checkbox-color);
}

table tbody.invisibleGridBody {
    height: 1px;
    visibility: hidden;
}

table tr td, table tr th {
    border-bottom: 1px solid var(--header-border-color);
}

table thead th:last-of-type .resizeHandle {
    display: none;
}

table tr:last-of-type td {
    border-bottom: unset;
}

.loadingFooter {
    color: var(--secondary-text-color);
}

.errorFooter [role="alert"]:not(:empty) {
    padding-block: var(--grid-row-vertical-spacing);
    text-align: center;
    font-weight: 600;
    background-color: var(--error-background-color);
    color: var(--error-color);
}