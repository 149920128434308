.radio-label-container {
    display: inline-flex;
    align-items: center;
}

.radio-container {
    margin: 0;
    padding: 0;
    outline: 0;
    min-width: 1px;
    min-height: 1px;
    border: none;
    display: inline-block;
    vertical-align: middle;
    width: fit-content;
    position: relative;
}

.radio-label-container .radio-container {
    margin-right: 10px;
}

.radio-container input[type="radio"] {
    position: absolute;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.radio-container:disabled input[type="radio"] {
    cursor: default;
}

.radio-styled {
    width: 18px;
    height: 18px;
    -webkit-transition: all var(--animation-speed);
    transition: all var(--animation-speed);
    vertical-align: bottom;
    position: relative;
    stroke-width: 3px;
    stroke-linecap: round;
    border: 1px solid var(--primary-neutral-color);
    border-radius: 100%;
    padding: 2px;
    fill: none;
}

.radio-container input[type="radio"]:checked + .radio-styled {
    fill: var(--primary-neutral-color);
}

.radio-container input[type="radio"]:focus-visible + .radio-styled {
    box-shadow: var(--focus-shadow);
}

.radio-container input[type="radio"]:enabled + .radio-styled {
    pointer-events: none;
}
