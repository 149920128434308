.countryAreaBasicData {
    grid-template-columns: repeat(5, min-content) repeat(2, auto) 1fr;
    grid-template-areas:
            "name               name                name                isoCode                 subdivisionsMandatory   subdivisionsMandatory   allowNameTranscriptions noFarmTcPermitted"
            "callingCode        .                   .                   .                       .                       .                       .                       ."
            "registrationIdType registrationIdType  registrationIdType  registrationIdTypeOmp   registrationIdTypeOmp   .                       .                       ."
            "startDate          endDate             .                   .                       .                       .                       .                       ."
            "stateProvince      stateProvince       stateProvince       stateProvince           stateProvince           stateProvince           stateProvince           stateProvince";
}

.countryAreaBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.countryAreaBasicData .isoCode {
    grid-area: isoCode;
    --width-by-chars: 20; /*  for label inside field  */
}

.countryAreaBasicData .subdivisionsMandatory {
    grid-area: subdivisionsMandatory;
}

.countryAreaBasicData .callingCode {
    grid-area: callingCode;
}

.countryAreaBasicData .registrationIdType {
    grid-area: registrationIdType;
    --width-by-chars: 30;
}

.countryAreaBasicData .registrationIdTypeOmp {
    grid-area: registrationIdTypeOmp;
    --width-by-chars: 30;
}

.countryAreaBasicData .startDate {
    grid-area: startDate;
}

.countryAreaBasicData .endDate {
    grid-area: endDate;
}

.countryAreaBasicData .stateProvince {
    grid-area: stateProvince;
}

.countryAreaBasicData .allowNameTranscriptions {
    grid-area: allowNameTranscriptions;
}

.countryAreaBasicData .noFarmTcPermitted {
    grid-area: noFarmTcPermitted;
}
