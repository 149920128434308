.checkbox-label-container {
    display: inline-flex;
    justify-self: flex-start;
}

.checkbox-container {
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    display: inline-block;
    vertical-align: middle;
    width: fit-content;
    position: relative;
}

.checkbox-container:disabled {
    background-color: var(--secondary-background-color);
}

.checkbox-label-container:disabled {
    color: var(--secondary-background-color);
}

.checkbox-label-container .checkbox-container {
    display: flex;
    margin: auto 10px auto 0;
}

.checkbox-container input[type="checkbox"] {
    position: absolute;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    opacity: .1;
}

.checkbox-container input[type="checkbox"] + .checkbox-indeterminate {
    background-color: var(--primary-neutral-color);
    stroke: white;
}

.checkbox-styled {
    width: 18px;
    height: 18px;
    -webkit-transition: all var(--animation-speed);
    transition: all var(--animation-speed);
    vertical-align: bottom;
    position: relative;
    stroke-width: 3px;
    stroke-linecap: round;
    border: 2px solid var(--primary-neutral-color);
    border-radius: 2px;
}

.checkbox-label-container input[type="checkbox"]:not(:disabled) + .checkbox-styled {
    cursor: pointer;
}

.checkbox-container input[type="checkbox"]:not(:checked) + .checkbox-styled {
    fill: none;
}

.checkbox-container input[type="checkbox"]:checked + .checkbox-styled {
    fill: none;
    stroke: white;
    background-color: var(--primary-neutral-color);
}

.checkbox-container input[type="checkbox"]:focus-visible {
    outline: transparent;
}

.checkbox-container input[type="checkbox"]:focus-visible + .checkbox-styled {
    box-shadow: var(--focus-shadow);
}

.checkbox-container input[type="checkbox"]:enabled + .checkbox-styled {
    pointer-events: none;
}
