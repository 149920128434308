.versionBasicData {
    grid-template-columns:
            min-content             min-content             min-content             min-content                     auto        1fr;
    grid-template-areas:
            "versionNumber          versionNumber           standard                logo                            logo        ."
            "startDate              endDate                 .                       .                               .           ."
            "defaultValidityPeriod  defaultValidityPeriod   recertificationPeriod   recertificationBindingPeriod    .           ."
            "labelGrades            labelGrades             labelGrades             labelGrades                     labelGrades labelGrades";
}

.versionBasicData .versionNumber {
    grid-area: versionNumber;
    --width-by-chars: 10;
}

.versionBasicData .standard {
    grid-area: standard;
    --width-by-chars: 10;
}

.versionBasicData .logo {
    grid-area: logo;
}

.versionBasicData .startDate {
    grid-area: startDate;
}

.versionBasicData .endDate {
    grid-area: endDate;
}

.versionBasicData .defaultValidityPeriod {
    grid-area: defaultValidityPeriod;
}

.versionBasicData .recertificationPeriod {
    grid-area: recertificationPeriod;
    --width-by-chars: 16; /*  for label inside field  */
}

.versionBasicData .recertificationBindingPeriod {
    grid-area: recertificationBindingPeriod;
    --width-by-chars: 22; /*  for label inside field  */
}

.versionBasicData .labelGrades {
    grid-area: labelGrades;
}
