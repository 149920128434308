.tcProductMaterialComposition {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "productCategoryPrint   productDetailPrint  labelGrade  ."
            "tcMaterials            tcMaterials         tcMaterials tcMaterials";
}

.tcProductMaterialComposition .productCategoryPrint {
    grid-area: productCategoryPrint;
    --width-by-chars: 40;
}

.tcProductMaterialComposition .productDetailPrint {
    grid-area: productDetailPrint;
    --width-by-chars: 40;
}

.tcProductMaterialComposition .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.tcProductMaterialComposition .tcMaterials {
    grid-area: tcMaterials;
}
