.tcProductMapView {
    height: 100%;
    display: flex;
    overflow: hidden;
}

.tcProductMapView .info {
    min-width: min-content;
    width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    word-wrap: break-word;
    padding: var(--layout-padding);
}

.tcProductMapView .info h1 {
    font-size: var(--24pt);
    white-space: nowrap;
    margin-block: 0 30px;
}

.tcProductMapView .info h2 {
    font-size: var(--18pt);
    white-space: pre-line;
    margin-block: -25px 30px;
}

.tcProductMapView .info hr {
    color: var(--primary-text-color);
}

.tcProductMapView .info dt, .tcProductMapView .info .materialPercentage {
    font-size: var(--18pt);
    font-weight: 600;
}

.tcProductMapView .info dd {
    font-size: var(--18pt);
    margin: 5px 0 25px;
}

.tcProductMapView .info dl dl {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-flow: dense;
}

.tcProductMapView .info .materialPercentage {
    grid-column: 1;
    margin: 25px 0 0;
}

.tcProductMapView .info .materialPercentage:not(:empty) {
    margin: 25px 25px 0 0;
}

.tcProductMapView .info .materialName {
    grid-column: 2;
    margin-top: 25px;
}

.tcProductMapView .info .materialOrigin {
    grid-column: 2;
    white-space: break-spaces;
    margin: 0;
}

.tcProductMapView .info dl dl .visuallyHidden {
    grid-column: 1;
    position: relative;
}

.tcProductMapView a:focus {
    outline: transparent;
}

.tcProductMapView .worldMap {
    --_markerRadius: .1rem;
    --_markerOutlineRadius: 0.18rem;
}

.tcProductMapView .productMarker {
    color: var(--product-traceability-color);
}

.tcProductMapView .materialMarker {
    color: var(--raw-material-traceability-color);
}

.tcProductMapView .markerGroupContainer {
    --_markerRadius: .25rem;
    --_markerOutlineRadius: .35rem;
}

.tcProductMapView .markerGroup {
    --_markerSpacingRadius: calc(1.7 * var(--_markerOutlineRadius));

    --_markerGroupMinXShift: calc(-1px * var(--_groupMarkerX) + var(--_markerOutlineRadius));
    --_markerGroupDefaultXShift: calc(-.5 * var(--_markerSpacingRadius) * (var(--_markerCount) - 1));
    --_markerGroupMaxXShift: calc((var(--_worldMapWidth) - var(--_groupMarkerX)) * 1px - (var(--_markerSpacingRadius) * (var(--_markerCount) - 1)));
    --_markerGroupXShift: clamp(var(--_markerGroupMinXShift), var(--_markerGroupDefaultXShift), var(--_markerGroupMaxXShift));

    --_markerGroupDefaultYShift: calc(-1 * var(--_markerSpacingRadius));
    --_markerGroupMaxYShift: calc(-1px * var(--_groupMarkerY) + var(--_markerSpacingRadius));
    --_markerGroupYShift: max(var(--_markerGroupMaxYShift), var(--_markerGroupDefaultYShift));
}

.tcProductMapView .markerGroup .marker {
    --_xShift: calc(var(--_markerSpacingRadius) * var(--_index));
    transform-box: fill-box;
    transform-origin: center;
    animation: scaleMarkerToPos forwards var(--animation-speed) ease-in-out;
}

@keyframes scaleMarkerToPos {
    from {
        translate: 0 -.25rem;
        scale: 0;
    }
    to {
        translate: calc(var(--_markerGroupXShift) + var(--_xShift)) var(--_markerGroupYShift);
        scale: .8;
    }
}

.tcProductMapView .groupMarker {
    color: var(--primary-neutral-color);
}

.tcProductMapView .marker circle:first-of-type {
    fill: currentColor;
    stroke: none;
    r: var(--_markerRadius);
}

.tcProductMapView .marker circle:last-of-type {
    fill: transparent;
    stroke: transparent;
    r: var(--_markerOutlineRadius);
    stroke-width: .03rem;
    stroke-dasharray: 0, 1;
    transition: stroke-dasharray ease-out var(--animation-speed);
}

.tcProductMapView .marker text {
    font-size: .35rem;
    font-weight: 700;
    translate: 0 .12rem;
    stroke: transparent;
    fill: var(--worldMapLand);
    pointer-events: none;
}

.tcProductMapView .connectingArc {
    stroke-width: .1rem;
    stroke-dasharray: 0, 1;
}

.tcProductMapView .tracedInput {
    stroke-dasharray: 1, 0;
}

.tcProductMapView .parentInput {
    stroke-dasharray: 8;
}

.tcProductMapView .connectingArc:is(.materialMarker, .productMarker) {
    fill: none;
    stroke: currentColor;
}

.tcProductMapView .marker:is([aria-disabled], .activeMarkerGroup) circle:last-of-type {
    stroke: currentColor;
    stroke-dasharray: 1, 0;
}

.tcProductMapView .marker:not([aria-disabled]):is(:focus-visible, :hover) circle:last-of-type {
    stroke: currentColor;
    stroke-dasharray: 1, 1;
}

.tcProductMapView .root-marker path {
    fill: var(--world-map-sea);
    stroke: var(--product-traceability-color);
    scale: .4;
    stroke-width: .125rem;
    transition: stroke ease-out var(--animation-speed);
}

.tcProductMapView .root-marker:is([aria-disabled], :focus-visible, :hover) path {
    fill: var(--product-traceability-color);
}

.tcProductMapView .goBackButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color);
    border-bottom-left-radius: var(--component-border-radius);
}
