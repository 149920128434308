.notepad-dialog .dialog-content .content {
    --width-by-chars: 0;
    min-width: 100%;
    width: 60vw;
    max-width: 120ch;
}

.notepad-dialog > .spinner {
    margin-top: 30px;
    height: 100%;
}
