.productDetailBasicData {
    grid-template-columns: repeat(4, auto) 1fr;
    grid-template-areas:
            "code        code       pickListSequence    pickListSequence ."
            "name        name       name                name             ."
            "startDate   endDate    endDate             .                .";
}

.productDetailBasicData .code {
    grid-area: code;
    --width-by-chars: 6;
}

.productDetailBasicData .pickListSequence {
    grid-area: pickListSequence;
}

.productDetailBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.productDetailBasicData .startDate {
    grid-area: startDate;
}

.productDetailBasicData .endDate {
    grid-area: endDate;
}

