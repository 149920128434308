.dynamicUserGroupBasicData {
    grid-template-columns: max-content 1fr;
    grid-template-areas:
            "name           ."
            "description    description"
            "definition     definition";
}

.dynamicUserGroupBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.dynamicUserGroupBasicData .description {
    grid-area: description;
}

.dynamicUserGroupBasicData .definition {
    grid-area: definition;
}
