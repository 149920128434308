.dropdown {
    position: relative;
}

.dropdown ul.dropdownMenu {
    position: absolute;
    z-index: 100;
    cursor: default;
    top: -5px;
    padding: 8px 0;
    background-color: var(--primary-background-color);
    border-radius: 5px;
    box-shadow: var(--layout-highlight-box-shadow);
    transform-origin: 50% 0;
    animation: slideInFromTop var(--animation-speed) linear;
    display: block;
    height: auto;
}

.dropdown ul[hidden] {
    display: none;
}

.dropdown .dropdownMenu .dropdownMenuItem {
    white-space: nowrap;
    color: var(--primary-text-color);
    margin: 0 20px;
    border-top: 1px solid var(--header-border-color);
}

.dropdown .dropdownMenu .dropdownMenuItem > * {
    width: 100%;
    height: 100%;
    padding: 12px 0;
    text-align: start;
}

.dropdown .dropdownMenu .dropdownMenuItem a {
    color: currentColor;
    text-decoration: none;
    display: inline-block;
}

.dropdown .dropdownMenu .dropdownMenuItem [aria-selected="true"] {
    color: var(--primary-color);
    font-weight: bold;
}

.dropdown .dropdownMenu .dropdownMenuItem:first-child, .dropdown .dropdownMenu hr + .dropdownMenuItem {
    border-top: 0;
}

.dropdown .dropdownMenu hr:last-child {
    display: none;
}

.dropdown .dropdownMenu hr {
    color: var(--main-menu-text-color);
    margin-left: 20px;
    margin-right: 20px;
    border: 0;
    border-top: 3px dotted;
}

.dropdown .dropdownMenu .dropdownMenuItem:hover,
.dropdown .dropdownMenu .dropdownMenuItem:focus-within {
    margin: 0;
}

.dropdown .dropdownMenu .dropdownMenuItem:hover > *,
.dropdown .dropdownMenu .dropdownMenuItem:focus-within > * {
    padding: 12px 20px;
    margin: 0;
    color: var(--dropdown-text-highlight-color);
    background-color: var(--main-menu-background-color);
}

.dropdown .dropdownMenu .dropdownMenuItem:hover,
.dropdown .dropdownMenu .dropdownMenuItem:hover + .dropdownMenuItem,
.dropdown .dropdownMenu .dropdownMenuItem:focus-within,
.dropdown .dropdownMenu .dropdownMenuItem:focus-within + .dropdownMenuItem {
    border-color: var(--main-menu-background-color);
}
