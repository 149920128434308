.organicMaterialProductionScBasicData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
        "scNumber                   scNumber                    scNumber                    issuingBody                 issuingBody                 ."
        "standard                   standard                    standard                    standard                    .                           ."
        "producer                   producer                    producer                    totalFarmArea               .                           ."
        "validFrom                  validUntil                  scCopy                      scCopy                      scCopy                      ."
        "organicMaterialProduction  organicMaterialProduction   organicMaterialProduction   organicMaterialProduction   organicMaterialProduction   organicMaterialProduction";
}

.organicMaterialProductionScBasicData .scNumber {
    grid-area: scNumber;
    --width-by-chars: 30;
}

.organicMaterialProductionScBasicData .issuingBody {
    grid-area: issuingBody;
    --width-by-chars: 80;
}

.organicMaterialProductionScBasicData .standard {
    grid-area: standard;
}

.organicMaterialProductionScBasicData .producer {
    grid-area: producer;
}

.organicMaterialProductionScBasicData .totalFarmArea {
    grid-area: totalFarmArea;
    /* because of label width*/
    --width-by-chars: 32;
}

.organicMaterialProductionScBasicData .validFrom {
    grid-area: validFrom;
}

.organicMaterialProductionScBasicData .validUntil {
    grid-area: validUntil;
}

.organicMaterialProductionScBasicData .scCopy {
    grid-area: scCopy;
}

.organicMaterialProductionScBasicData .organicMaterialProduction {
    grid-area: organicMaterialProduction;
}