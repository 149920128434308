.deduplicationLogData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "timestamp              .                       comment                 ."
            "sourceId               sourceBusinessName      comment                 ."
            "destinationId          destinationBusinessName comment                 ."
            "transferredAttributes  transferredAttributes   transferredEntities     ."
            "transferredRelations   transferredRelations    .                       .";
}

.deduplicationLogData .timestamp {
    grid-area: timestamp;
    --width-by-chars: 20;
}

.deduplicationLogData .comment {
    grid-area: comment;
}

.deduplicationLogData .sourceId {
    grid-area: sourceId;
    --width-by-chars: 20;
}

.deduplicationLogData .sourceBusinessName {
    grid-area: sourceBusinessName;
    --width-by-chars: 40;
}

.deduplicationLogData .destinationId {
    grid-area: destinationId;
}

.deduplicationLogData .destinationBusinessName {
    grid-area: destinationBusinessName;
}

.deduplicationLogData .transferredAttributes {
    grid-area: transferredAttributes;
}

.deduplicationLogData .transferredEntities {
    grid-area: transferredEntities;
}

.deduplicationLogData .transferredRelations {
    grid-area: transferredRelations;
}
