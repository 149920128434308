.MuiAutocomplete-root {
    /*                  --width-of-n  *     --number-of-chars + padding */
    min-width: calc(var(--width-of-n) * var(--width-by-chars) + 2 * var(--textfield-horizontal-padding) + 74px)
}

.MuiAutocomplete-root .pick-list .MuiOutlinedInput-root .MuiAutocomplete-input {
    height: 2rem;
    padding-top: 0;
    padding-bottom: 0;
}

.gtbPicklistPopper ul[role="listbox"] {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr);
}

.gtbPicklistPopper .pick-list-item {
    white-space: normal;
    color: var(--primary-text-color);
    margin: 0 20px;
    border-top: 1px solid var(--header-border-color);
}

.gtbPicklistPopper li.pick-list-item.MuiAutocomplete-option {
    white-space: pre;
    padding: 12px 0;
    text-align: start;
    background-color: white;
}

.gtbPicklistPopper .pick-list-item:first-child {
    border-top: 0;
}

.gtbPicklistPopper .pick-list-item:hover {
    margin: 0;
}

.gtbPicklistPopper li.pick-list-item.MuiAutocomplete-option:hover {
    padding: 12px 20px;
    color: var(--dropdown-text-highlight-color);
    background-color: var(--main-menu-background-color);
}

.gtbPicklistPopper li.pick-list-item.MuiAutocomplete-option[aria-selected="true"], .gtbPicklistPopper .pick-list-item.Mui-focused.MuiAutocomplete-option {
    padding: 12px 20px;
    margin: 0;
    color: var(--dropdown-text-highlight-color);
    border-color: var(--main-menu-background-color);
    background-color: var(--main-menu-background-color);
}

.gtbPicklistPopper .pick-list-item:hover, .gtbPicklistPopper .pick-list-item[aria-selected="true"] + .pick-list-item, .gtbPicklistPopper .pick-list-item:hover + .pick-list-item, .gtbPicklistPopper .pick-list-item.Mui-focused + .pick-list-item {
    border-color: var(--main-menu-background-color);
}
