.uploadLogData {
    grid-template-columns: min-content max-content min-content 1fr;
    grid-template-areas:
            "timestamp logLevel certificationBody ."
            "message message message message"
            "className className className ."
            "remarks remarks remarks remarks";
}

.uploadLogData .timestamp {
    grid-area: timestamp;
    --width-by-chars: 20;
}

.uploadLogData .level {
    grid-area: logLevel;
}

.uploadLogData .message {
    grid-area: message;
}

.uploadLogData .className {
    grid-area: className;
    --width-by-chars: 80;
}

.uploadLogData .certificationBody {
    grid-area: certificationBody;
    --width-by-chars: 80;
}

.uploadLogData .remarks {
    grid-area: remarks;
}
