.spinner > svg {
    animation: spin calc(var(--animation-speed) * 20) linear infinite;
}

.spinner {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.spinner.fullscreenSpinner {
    height: 100vh;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
