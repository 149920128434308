.certificationRelationData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
            "certificationBody      certificationBody       certificationBody       .                       .                   ."
            "supplyChainOperator    supplyChainOperator     supplyChainOperator     standard                cbLicenceNumber     ."
            "recertificationEmail   recertificationEmail    recertificationEmail    certificationBodyCc     .                   ."
            "accessLevel            accessLevel             accessLevel             accessLevel             accessLevel         ."
            "startDate              endDate                 .                       .                       .                   .";
}

.certificationRelationData .certificationBody {
    grid-area: certificationBody;
    --width-by-chars: 40;
}

.certificationRelationData .supplyChainOperator {
    grid-area: supplyChainOperator;
    --width-by-chars: 40;
}

.certificationRelationData .standard {
    grid-area: standard;
    --width-by-chars: 40;
}

.certificationRelationData .cbLicenceNumber {
    grid-area: cbLicenceNumber;
    --width-by-chars: 40;
}

.certificationRelationData .accessLevel {
    grid-area: accessLevel;
}

.certificationRelationData .startDate {
    grid-area: startDate;
}

.certificationRelationData .endDate {
    grid-area: endDate;
}

.certificationRelationData .recertificationEmail {
    grid-area: recertificationEmail;
    --width-by-chars: 40;
}

.certificationRelationData .certificationBodyCc {
    grid-area: certificationBodyCc;
}
