.productConversionBasicData {
    grid-template-columns: repeat(4, min-content) 1fr;
    grid-template-areas:
            "processCategory        processCategory         rawMaterial         rawMaterial ."
            "inputProductCategory   inputProductCategory    inputProductDetail  .           ."
            "outputProductCategory  outputProductCategory   outputProductDetail .           ."
            "minConversion          maxConversion           .                   .           .";
}

.productConversionBasicData .processCategory {
    grid-area: processCategory;
}

.productConversionBasicData .rawMaterial {
    --width-by-chars: 80;
    grid-area: rawMaterial;
}

.productConversionBasicData .inputProductCategory {
    --width-by-chars: 40;
    grid-area: inputProductCategory;
}

.productConversionBasicData .inputProductDetail {
    --width-by-chars: 40;
    grid-area: inputProductDetail;
}

.productConversionBasicData .outputProductCategory {
    --width-by-chars: 40;
    grid-area: outputProductCategory;
}

.productConversionBasicData .outputProductDetail {
    --width-by-chars: 40;
    grid-area: outputProductDetail;
}

.productConversionBasicData .minConversion {
    grid-area: minConversion;
    --width-by-chars: 20; /*  for label inside field  */
}

.productConversionBasicData .maxConversion {
    grid-area: maxConversion;
    --width-by-chars: 20; /*  for label inside field  */
}
