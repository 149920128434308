.authorisationType h2 {
    margin-bottom: 0;
    font-size: var(--18pt);
    margin-top: var(--layout-vertical-spacing);
}

.authorisationType .accessRight, .authorisationType .stateSpecificAccessRight {
    padding: var(--layout-horizontal-spacing) 0 0 var(--layout-horizontal-spacing);
}

.stateSpecificAccessRight {
    grid-template-columns: minmax(max-content, 6rem) minmax(min-content, 50rem);
}
