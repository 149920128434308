.resetPassword {
    display: flex;
    flex-direction: column;
}

.resetPassword .resetPassword-form-password {
    margin: 0 0 15px 0;
}

.resetPassword .resetPassword-form-button {
    margin: 38px 0 30px;
    text-decoration: none;
}

.resetPassword .resetPassword-form-info {
    align-self: flex-start;
}

.resetPassword .resetPassword-form-info span {
    color: var(--external-secondary-color);
}
