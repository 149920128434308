.mailTemplateData {
    grid-template-columns: min-content 1fr;
    grid-template-areas:
            "subject    ."
            "body       .";
}

.mailTemplateData .subject {
    grid-area: subject;
    --width-by-chars: 80;
}

.mailTemplateData .body {
    grid-area: body;
    --width-by-chars: 80;
}