.eventMonitorData {
    grid-template-columns: min-content min-content min-content 1fr;
    grid-template-areas:
            "eventType              name                        name ."
            "description            description                 description ."
            "signallingThreshold    observationFrameInSeconds   active active"
            "additionalText         additionalText              additionalText ."
            "notifySystemAdmins     notifyStandardBodyAdmins    notifyStandardBodyAdmins ."
            "standardBody           standardBody                standardBody ."
            "additionalRecipients   additionalRecipients        additionalRecipients .";
}

.eventMonitorData .eventType {
    grid-area: eventType;
}

.eventMonitorData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.eventMonitorData .description {
    grid-area: description;
}

.eventMonitorData .signallingThreshold {
    grid-area: signallingThreshold;
    --width-by-chars: 20;
}

.eventMonitorData .observationFrameInSeconds {
    grid-area: observationFrameInSeconds;
    --width-by-chars: 20;
}

.eventMonitorData .active {
    grid-area: active;
}

.eventMonitorData .additionalText {
    grid-area: additionalText;
}

.eventMonitorData .notifySystemAdmins {
    grid-area: notifySystemAdmins;
}

.eventMonitorData .notifyStandardBodyAdmins {
    grid-area: notifyStandardBodyAdmins;
}

.eventMonitorData .standardBody {
    grid-area: standardBody;
}

.eventMonitorData .additionalRecipients {
    grid-area: additionalRecipients;
}
