.fileUploadContainer {
    color: var(--primary-neutral-color);
    overflow: hidden;
    display: grid;
    grid-template-columns: auto min-content;
}

.fileUploadContainer .itemWrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-grow: 1;
}

.fileUploadContainer .itemWrapper > :first-child {
    margin: 0 auto 0 var(--textfield-horizontal-padding);
    color: var(--primary-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fileUploadContainer .upload {
    background-color: var(--primary-background-color);
    color: var(--primary-color);
    border-left: 1px solid var(--primary-neutral-color);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.fileUploadContainer .upload:disabled {
    background-color: var(--primary-background-color);
    color: var(--primary-neutral-color);
}

.fileUploadContainer .deleteFile {
    margin: 0 var(--textfield-horizontal-padding);
    color: currentColor;
}

.fileWarning:not(.inputError) .helperText {
    grid-area: fileSizeWarning;
    color: var(--warning-color);
}
