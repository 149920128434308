.homepage {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, 1fr));
    grid-template-rows: min-content max-content auto;
    grid-column-gap: 60px;
    grid-template-areas:
            "breadcrumbs    dashboardSettings"
            "markdown       dashboardSettings"
            "markdown       dashboardPreview";
}

.homepage .breadcrumbContainer {
    grid-area: breadcrumbs;
}

.homepage .markdown-container {
    grid-area: markdown;
}

.homepage .dashboardSettings {
    color: var(--primary-color);
    grid-area: dashboardSettings;
    position: absolute;
    right: 0;
    top: 0;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-items: flex-end;
    grid-gap: 5px;
}

.homepage .dashboardSettings.hidden {
    visibility: hidden;
    grid-area: dashboardSettings;
    position: static;
}

.homepage .dashboardSettings .arrowIcon {
    margin-top: var(--14pt);
}

.homepage .dashboardSettings strong {
    font-size: var(--20pt);
}

.homepage img {
    grid-area: dashboardPreview;
    max-width: 100%;
    height: auto;
    justify-self: center;
    margin-top: 60px;
}
