.login {
    display: flex;
    flex-direction: column;
}

.login .login-form-password {
    margin: 15px 0;
}

.login .login-form-forgot-password {
    align-self: flex-end;
}

.login .login-form-submit {
    margin: 38px 0 30px;
}

.login a {
    text-decoration: none;
}

.login .login-form-register {
    align-self: flex-start;
}

.login .login-form-register span:after {
    content: " ";
}

.login .login-form-info {
    align-self: flex-start;
    margin-bottom: 15px;
    margin-top: -45px;
}

.login .login-form-info span {
    color: var(--highlight-color);
}

.login .environment-info {
    color: var(--warning-color);
    text-align: center;
    font-weight: bold;
    font-size: 16pt;
    margin-bottom: 20px;
}