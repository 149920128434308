.supplyChainOperatorFacilities {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "mainFacility   mainFacility    mainFacility    ."
            "facilities     facilities      facilities      facilities";
}

.supplyChainOperatorFacilities .mainFacility {
    grid-area: mainFacility;
    --width-by-chars: 40;
}

.supplyChainOperatorFacilities .facilities {
    grid-area: facilities;
}