.organicMaterialProducerOtherData {
    grid-template-columns: min-content 1fr;
    grid-template-areas:
            "numberOfFarmers    ."
            "cbInternalId       ."
            "externalIds        externalIds";
}

.organicMaterialProducerOtherData .numberOfFarmers {
    /* For label size */
    --width-by-chars: 15;
}

.organicMaterialProducerOtherData .cbInternalId {
    grid-area: cbInternalId;
    --width-by-chars: 40;
}

.organicMaterialProducerOtherData .externalIds {
    grid-area: externalIds;
}
