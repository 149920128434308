.worldMap {
    position: relative;
    width: 100%;
    padding: 0 var(--layout-padding) var(--layout-padding);
    background-color: var(--world-map-sea);
    overflow: auto;
}

.worldMap > svg {
    display: block;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-height: 400px;
    overflow: visible;
}

.worldMap-legend-anchor {
    position: sticky;
    bottom: 0;
    height: 0;
}

.worldMap-legend {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: var(--layout-action-spacing);
    color: var(--primary-text-color);
}

.worldMap-legend span {
    display: flex;
    align-items: center;
    gap: 5px;
}
