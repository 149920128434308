.transactionCertificateDocument {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "certificateOrigin      certificateOrigin       certificateOrigin     ."
            "certificateDocument    certificateDocument     certificateDocument     ."
            "cbCertificate          cbCertificate           cbCertificate           ."
            "documentHash           hashAlgorithm           documentSize            .";
}

.transactionCertificateDocument .certificateOrigin {
    grid-area: certificateOrigin;
}

.transactionCertificateDocument .certificateDocument {
    grid-area: certificateDocument;
}

.transactionCertificateDocument .cbCertificate {
    grid-area: cbCertificate;
}

.transactionCertificateDocument .documentHash {
    grid-area: documentHash;
    --width-by-chars: 64;
}

.transactionCertificateDocument .hashAlgorithm {
    grid-area: hashAlgorithm;
    --width-by-chars: 12;
}

.transactionCertificateDocument .documentSize {
    grid-area: documentSize;
    --width-by-chars: 12;
}
