.preGtbTransactionCertificateBasicData {
    grid-template-columns: var(--datepicker-width) repeat(3, min-content) 1fr;
    grid-template-areas:
        "tcNumber       tcNumber    certificationBody   certificationBody   ."
        "standard       standard    standard            standard            ."
        "buyer          buyer       buyer               seller              ."
        "dateOfIssue    status      status              .                   .";
}

.preGtbTransactionCertificateBasicData .tcNumber {
    grid-area: tcNumber;
    --width-by-chars: 30;
}

.preGtbTransactionCertificateBasicData .certificationBody {
    grid-area: certificationBody;
}

.preGtbTransactionCertificateBasicData .standard {
    grid-area: standard;
}


.preGtbTransactionCertificateBasicData .buyer {
    grid-area: buyer;
}

.preGtbTransactionCertificateBasicData .sellersLicenceNumber {
    grid-area: seller;
    --width-by-chars: 30;
}

.preGtbTransactionCertificateBasicData .dateOfIssue {
    grid-area: dateOfIssue;
}

.preGtbTransactionCertificateBasicData .status {
    grid-area: status;
}
