.buyingRelationData {
    grid-template-columns: repeat(5, min-content) 1fr;
    grid-template-areas:
            "seller         seller      seller      .                   .                   ."
            "buyer          buyer       buyer       standard            standard            ."
            "accessLevel    accessLevel accessLevel confirmationMethod  confirmationMethod  confirmationMethod"
            "startDate      endDate     .           .                   .                   .";
}

.buyingRelationData .seller {
    grid-area: seller;
    --width-by-chars: 40;
}

.buyingRelationData .buyer {
    grid-area: buyer;
    --width-by-chars: 40;
}

.buyingRelationData .standard {
    grid-area: standard;
    --width-by-chars: 40;
}

.buyingRelationData .accessLevel {
    grid-area: accessLevel;
}

.buyingRelationData .confirmationMethod {
    grid-area: confirmationMethod;
}

.buyingRelationData .startDate {
    grid-area: startDate;
}

.buyingRelationData .endDate {
    grid-area: endDate;
}
