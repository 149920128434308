.organisationAddressData {
    grid-template-columns: repeat(3, min-content) minmax(200px, 1fr);
}

.organisationAddressData .town {
    grid-column: 1;
    --width-by-chars: 40;
}

.organisationAddressData .postCode {
    grid-column: 2;
    --width-by-chars: 10;
}


.organisationAddressData .countryArea {
    grid-column: 1;
}

.organisationAddressData .stateProvince {
    grid-column: 2/4;
}

.organisationAddressData .addressDetails1 {
    grid-column: 1/4;
    --width-by-chars: 100;
}

.organisationAddressData .addressDetails2 {
    grid-column: 1/4;
    --width-by-chars: 100;
}

.organisationAddressData .addressDetails3 {
    grid-column: 1/4;
    --width-by-chars: 100;
}

.organisationAddressData .latitude {
    grid-column: 1;
    --width-by-chars: 10;
}

.organisationAddressData .longitude {
    grid-column: 2/4;
    --width-by-chars: 10;
}

.organisationAddressData .mapWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-background-color);
    color: var(--primary-neutral-color);
    border: 1px solid;
    border-radius: var(--component-border-radius);
    grid-column: 4;
    grid-row: 1/7;
    overflow: hidden;
}

.mapWrapper #map {
    width: 100%;
    height: 100%;
}

.supplyChainOperatorAddressData .typeOfRegistrationId, .facilityAddressData .typeOfRegistrationId, .organicMaterialProducerAddressData .typeOfRegistrationId {
    grid-column: 1;
    --width-by-chars: 30;
}

.supplyChainOperatorAddressData .fixedRegistrationId, .facilityAddressData .fixedRegistrationId, .organicMaterialProducerAddressData .fixedRegistrationId {
    grid-column: 2/4;
    --width-by-chars: 30;
}

.supplyChainOperatorAddressData .mapWrapper, .facilityAddressData .mapWrapper, .organicMaterialProducerAddressData .mapWrapper {
    grid-row: 1/8;
}
