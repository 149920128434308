.organicMaterialTransactionCertificateBasicData {
    grid-template-columns: repeat(4, min-content) 1fr;
    grid-template-areas:
        "buyer                      buyer                      certifiedOrganicMaterial     certifiedOrganicMaterial    ."
        "organicMaterialProducer    organicMaterialProducer    organicMaterialProduction    organicMaterialProduction   ."
        "tcNumber                   type                       type                         standard                    ."
        "tcFile                     tcFile                     tcFile                       .                           .";
}

.organicMaterialTransactionCertificateBasicData .buyer {
    grid-area: buyer;
    --width-by-chars: 40;
}

.organicMaterialTransactionCertificateBasicData .certifiedOrganicMaterial {
    grid-area: certifiedOrganicMaterial;
    --width-by-chars: 80;
}

.organicMaterialTransactionCertificateBasicData .organicMaterialProducer {
    grid-area: organicMaterialProducer;
}

.organicMaterialTransactionCertificateBasicData .organicMaterialProduction {
    grid-area: organicMaterialProduction;
}

.organicMaterialTransactionCertificateBasicData .tcNumber {
    grid-area: tcNumber;
    --width-by-chars: 30;
}

.organicMaterialTransactionCertificateBasicData .type {
    grid-area: type;
}

.organicMaterialTransactionCertificateBasicData .standard {
    grid-area: standard;
    --width-by-chars: 40;
}

.organicMaterialTransactionCertificateBasicData .tcFile {
    grid-area: tcFile;
}