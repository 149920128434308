button {
    cursor: pointer;
    padding: 0;
}

button:focus-visible {
    box-shadow: var(--focus-shadow);
    outline: none;
}

button[disabled] {
    cursor: default;
}

button.iconOnLeft svg {
    margin-right: 8px;
}

button.iconOnRight svg {
    margin-left: 8px;
}

button.primary-button:disabled {
    background-color: var(--primary-neutral-color);
}

.primary-button {
    font-size: var(--18pt);
    font-weight: bold;
    border: none;
    background-color: var(--primary-color);
    border-radius: 5px;
    color: var(--primary-background-color);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.secondary-button:disabled {
    color: var(--primary-neutral-color);
}

.secondary-button {
    font-weight: bold;
    background-color: var(--primary-background-color);
    border-radius: 5px;
    color: var(--primary-color);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondary-button.large-button {
    border: 2px solid;
    font-size: var(--18pt);
}

.secondary-button.medium-button {
    border: 1px solid;
}

.secondary-button.small-button {
    border: 1px solid;
}

button.secondary-color-scheme {
    --primary-color: var(--list-column-header-color);
    background-color: var(--secondary-background-color);
}

.tertiary-button {
    background-color: transparent;
    border: none;
    font-size: var(--16pt);
}

.tertiary-button.large-button {
    height: auto;
}

.large-button {
    height: var(--min-component-height);
}

.medium-button {
    /* 32px */
    height: 2rem;
}

.small-button {
    /* 24px */
    height: 1.5rem;
}
