.tcProductBasicData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "tcProductNumber    relatedScProduct        cbProductReferenceNumber ."
            "seller             sellerFacility          .                        ."
            "buyer              buyerFacility           .                        ."
            "productCategory    productCategoryPrint    .                        ."
            "productDetail      productDetailPrint      .                        ."
            "labelGrade         usdaNopCompliant        .                        .";
}

.tcProductBasicData .tcProductNumber {
    grid-area: tcProductNumber;
}

.tcProductBasicData .relatedScProduct {
    grid-area: relatedScProduct;
}

.tcProductBasicData .seller {
    grid-area: seller;
    --width-by-chars: 40;
}

.tcProductBasicData .sellerFacility {
    grid-area: sellerFacility;
    --width-by-chars: 40;
}

.tcProductBasicData .buyer {
    grid-area: buyer;
}

.tcProductBasicData .buyerFacility {
    grid-area: buyerFacility;
}

.tcProductBasicData .productCategory {
    grid-area: productCategory;
}

.tcProductBasicData .productCategoryPrint {
    grid-area: productCategoryPrint;
}

.tcProductBasicData .productDetail {
    grid-area: productDetail;
}

.tcProductBasicData .productDetailPrint {
    grid-area: productDetailPrint;
}

.tcProductBasicData .labelGrade {
    grid-area: labelGrade;
}

.tcProductBasicData .usdaNopCompliant {
    grid-area: usdaNopCompliant;
}

.cbProductReferenceNumber {
    grid-area: cbProductReferenceNumber;
    --width-by-chars: 30;
}