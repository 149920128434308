.listPlaceholderHeader {
    height: 32px;
    margin-bottom: var(--layout-action-spacing-small);
    padding-top: 4px;
}

.listPlaceholderMessage {
    min-width: 100%;
    height: 200px;
    background-color: var(--secondary-background-color);
    color: var(--tertiary-text-color);
    padding: var(--layout-action-spacing-small);
    border: 1px solid;
    border-radius: var(--component-border-radius);
    box-shadow: var(--layout-highlight-box-shadow);
}
