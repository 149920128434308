.forgotPassword {
    display: flex;
    flex-direction: column;
}

.forgotPassword .forgotPassword-form-username {
    margin: 0 0 15px 0;
}

.forgotPassword .forgotPassword-form-submit {
    margin: 38px 0 0;
}

.forgotPassword .forgotPassword-form-info {
    align-self: flex-start;
}
