.tcMaterialBasicData {
    grid-template-columns: repeat(5, min-content);
}

.tcMaterialBasicData .productNumber {
    --width-by-chars: 20;
    grid-column: 1 / 3;
}

.tcMaterialBasicData .productCategoryPrint {
    --width-by-chars: 50;
    grid-column: 1 / 4;
}

.tcMaterialBasicData .productDetailPrint {
    --width-by-chars: 50;
    grid-column: 4;
}

.tcMaterialBasicData .labelGrade {
    --width-by-chars: 40;
    grid-column: 5;
}

.tcMaterialBasicData .rawMaterial {
    --width-by-chars: 40;
    grid-column: 1 / 4;
}

.tcMaterialBasicData .rawMaterialPrint {
    --width-by-chars: 50;
    grid-column: 4;
}

.tcMaterialBasicData .certified {
    /*Auto Column*/
}

.tcMaterialBasicData .percentage {
    /* Because of label width*/
    --width-by-chars: 8;
    grid-column: 1;
}

.tcMaterialBasicData .certifiedWeight {
    /* Because of label width*/
    --width-by-chars: 10;
    grid-column: 2;
}

.tcMaterialBasicData .origin {
    grid-column: 1 / 5;
}
