.organicMaterialProductionAddressData {
    grid-template-columns: repeat(3, min-content) minmax(200px, 1fr);
}

.organicMaterialProductionAddressData .town {
    grid-column: 1;
    --width-by-chars: 40;
}

.organicMaterialProductionAddressData .postCode {
    grid-column: 2;
    --width-by-chars: 10;
}

.organicMaterialProductionAddressData .countryArea {
    grid-column: 1;
}

.organicMaterialProductionAddressData .stateProvince {
    grid-column: 2/4;
}

.organicMaterialProductionAddressData .addressDetails1 {
    grid-column: 1;
    --width-by-chars: 40;
}

.organicMaterialProductionAddressData .addressDetails2 {
    grid-column: 2/4;
    --width-by-chars: 40;
}

.organicMaterialProductionAddressData .addressDetails3 {
    grid-column: 1;
    --width-by-chars: 40;
}