.additionalMaterialBasicData {
    grid-template-columns: repeat(3, min-content);
    grid-template-areas:
            "standardVersion    labelGrade          minPercentage"
            "certifiedMaterial  certifiedMaterial   ."
            "rawMaterial        rawMaterial         maximumPercentage";
}

.additionalMaterialBasicData .standardVersion {
    grid-area: standardVersion;
    --width-by-chars: 40;
}

.additionalMaterialBasicData .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.additionalMaterialBasicData .certifiedMaterial {
    grid-area: certifiedMaterial;
    --width-by-chars: 80;
}

.additionalMaterialBasicData .minPercentage {
    grid-area: minPercentage;
    --width-by-chars: 15;
}

.additionalMaterialBasicData .rawMaterial {
    grid-area: rawMaterial;
    --width-by-chars: 80;
}

.additionalMaterialBasicData .maximumPercentage {
    grid-area: maximumPercentage;
    --width-by-chars: 15;
}
