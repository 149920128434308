.supplyChainOperatorBasicData {
    grid-template-columns: repeat(7, min-content);
}

.sco__reducedAccessWarning {
    margin-top: calc(-1 * var(--_subtitleAdditionalHeight));
    float: right;
    font-weight: 600;
    color: var(--highlight-color)
}

.supplyChainOperatorBasicData .legalName {
    grid-column: 1 / 6;
    --width-by-chars: 80;
}

.supplyChainOperatorBasicData .legalNameInEnglish {
    grid-column: 1 / 6;
    --width-by-chars: 80;
}

.supplyChainOperatorBasicData .businessName {
    grid-area: unset;
    grid-column: 1 / 6;
    --width-by-chars: 80;
}

.supplyChainOperatorBasicData .website {
    grid-area: unset;
    grid-column: 1 / 4;
}

.supplyChainOperatorBasicData .organisationalEmail {
    grid-area: unset;
    grid-column: 4 / 7;
}


.supplyChainOperatorBasicData .organisationalPhoneNumber {
    grid-area: unset;
    grid-column: 7;
}

.supplyChainOperatorBasicData .publicContactPersonName {
    grid-area: unset;
    grid-column: 1 / 5;
    /* instead of 80 because of arrangement requirements */
    --width-by-chars: 60;
}

.supplyChainOperatorBasicData .publicContactPersonEmail {
    grid-area: unset;
    grid-column: 5 / 8;
    /* instead of 100 because of arrangement requirements */
    --width-by-chars: 60;
}

.supplyChainOperatorBasicData .organisationalContactPersonName {
    grid-area: unset;
    grid-column: 1 / 5;
    /* instead of 80 because of arrangement requirements */
    --width-by-chars: 60;
}

.supplyChainOperatorBasicData .organisationalContactPersonEmail {
    grid-area: unset;
    grid-column: 5 / 8;
    /* instead of 100 because of arrangement requirements */
    --width-by-chars: 60;
}

.supplyChainOperatorBasicData .startDate {
    grid-area: unset;
    grid-column: 1;
}

.supplyChainOperatorBasicData .endDate {
    grid-area: unset;
    grid-column: 2;
}

.supplyChainOperatorBasicData .status {
    grid-area: unset;
    grid-column: 4;
}
