.applicationLogData {
    grid-template-columns: min-content max-content min-content 1fr;
    grid-template-areas:
            "timestamp logLevel . ."
            "message message message message"
            "className className className session"
            "remarks remarks remarks remarks";
}

.applicationLogData .timestamp {
    grid-area: timestamp;
    --width-by-chars: 20;
}

.applicationLogData .level {
    grid-area: logLevel;
}

.applicationLogData .message {
    grid-area: message;
}

.applicationLogData .className {
    grid-area: className;
    --width-by-chars: 80;
}

.applicationLogData .session {
    grid-area: session;
}

.applicationLogData .remarks {
    grid-area: remarks;
}
