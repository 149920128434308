.reactiveDeduplicationDialog {
    grid-template-columns: repeat(2, min-content);
    grid-template-areas:
            "source     destination"
            "comment    comment";
    grid-auto-rows: min-content;
}

.reactiveDeduplicationDialog .source {
    grid-area: source;
    --width-by-chars: 40;
}

.reactiveDeduplicationDialog .destination {
    grid-area: destination;
    --width-by-chars: 40;
}

.reactiveDeduplicationDialog .comment {
    grid-area: comment;
}
