.organicMaterialProducerBasicData {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "organicMaterialProducer        organicMaterialProducer     .                           ."
            "certifiedOrganicMaterial       usdaNopCompliant            .                           ."
            "harvestYear                    .                           .                           ."
            "sizeOfProductionArea           estimatedQuantity           soldQuantity                ."
            "organicMaterialProductionSc    organicMaterialProductionSc organicMaterialProductionSc organicMaterialProductionSc"
}

.organicMaterialProducerBasicData .organicMaterialProducer {
    grid-area: organicMaterialProducer;
    --width-by-chars: 80;
}

.organicMaterialProducerBasicData .certifiedOrganicMaterial {
    grid-area: certifiedOrganicMaterial;
    --width-by-chars: 40;
}

.organicMaterialProducerBasicData .usdaNopCompliant {
    grid-area: usdaNopCompliant;
}

.organicMaterialProducerBasicData .harvestYear {
    grid-area: harvestYear;
    --width-by-chars: 40;
}

.organicMaterialProducerBasicData .sizeOfProductionArea {
    grid-area: sizeOfProductionArea;
    --width-by-chars: 40;
}

.organicMaterialProducerBasicData .estimatedQuantity {
    grid-area: estimatedQuantity;
    --width-by-chars: 40;
}

.organicMaterialProducerBasicData .soldQuantity {
    grid-area: soldQuantity;
    --width-by-chars: 40;
}

.organicMaterialProducerBasicData .organicMaterialProductionSc {
    grid-area: organicMaterialProductionSc;
}
