.timeBasedMailData {
    grid-template-columns: repeat(4, min-content) 1fr;
    grid-template-areas:
            "subject    targetDate  targetTime  sent        ."
            "body       userGroup   userGroup   userGroup   ."
            "body       .           .           .           .";
}

.timeBasedMailData .subject {
    grid-area: subject;
    --width-by-chars: 80;
}

.timeBasedMailData .targetDate {
    grid-area: targetDate;
}

.timeBasedMailData .targetTime {
    grid-area: targetTime;
}

.timeBasedMailData .sent {
    grid-area: sent;
}

.timeBasedMailData .body {
    grid-area: body;
    --width-by-chars: 80;
}

.timeBasedMailData .userGroup {
    grid-area: userGroup;
}