.recordNavigation nav {
    display: flex;
    justify-content: flex-end;
}

.recordNavigation a {
    color: var(--primary-neutral-color);
    margin-left: var(--layout-action-spacing);
}

.recordNavigation .recordNavigationWarning {
    color: var(--highlight-color);
}
