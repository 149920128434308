.allowedMaterialBasicData {
    grid-template-columns: max-content 1fr;
    grid-template-areas:
            "standard       ."
            "rawMaterial    .";
}

.allowedMaterialBasicData .standard {
    grid-area: standard;
    --width-by-chars: 80;
}

.allowedMaterialBasicData .rawMaterial {
    grid-area: rawMaterial;
    --width-by-chars: 80;
}