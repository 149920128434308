.certificationBodyBasicData {
    grid-template-columns: repeat(8, min-content) 1fr;
    grid-template-areas:
            "legalName      legalName       legalName       legalName               legalName           .                       .                           ."
            "businessName   businessName    businessName    .                       .                   .                       .                           ."
            "website        website         website         organisationalEmail     organisationalEmail organisationalEmail     organisationalPhoneNumber   ."
            "startDate      endDate         .               status                  .                   .                       .                           .";
}

.certificationBodyBasicData .legalName {
    grid-area: legalName;
    --width-by-chars: 80;
}