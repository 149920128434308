.productCategoryBasicData {
    grid-template-columns: repeat(3, max-content) 1fr;
    grid-template-areas:
            "code               code                 pickListSequence   pickListSequence"
            "name        name                       typeOfMaterial                       typeOfMaterial"
            "activation         activation              activation              .";
}

.productCategoryBasicData .code {
    grid-area: code;
    --width-by-chars: 6;
}

.productCategoryBasicData .pickListSequence {
    grid-area: pickListSequence;
    max-width: 19rem;
}

.productCategoryBasicData .name {
    grid-area: name;
    --width-by-chars: 40;
}

.productCategoryBasicData .activation-period {
    grid-area: activation;
    display: flex;
    gap: var(--layout-horizontal-spacing);
}

