.facilityBasicData {
    grid-template-columns: repeat(8, min-content) 1fr;
}

.facilityBasicData.gridExcludingLegalNameInEnglish {
    grid-template-areas:
            "scoBusinessName        scoBusinessName         scoBusinessName         scoLegalName        scoLegalName        scoLegalName        scoLegalName                .   ."
            "independentLegalEntity independentLegalEntity  independentLegalEntity  .                   .                   .                   .                           .   ."
            "businessName           businessName            businessName            legalName           legalName           legalName           legalName                   .   ."
            "website                website                 website                 organisationalEmail organisationalEmail organisationalEmail organisationalPhoneNumber   .   ."
            "startDate              endDate                 .                       status              status              .                   .                           .   .";
}

.facilityBasicData.gridIncludingLegalNameInEnglish {
    grid-template-areas:
            "scoBusinessName        scoBusinessName         scoBusinessName         scoLegalName        scoLegalName        scoLegalName        scoLegalName                .   ."
            "independentLegalEntity independentLegalEntity  independentLegalEntity  .                   .                   .                   .                           .   ."
            "businessName           businessName            businessName            legalName           legalName           legalName           legalName                   .   ."
            ".                      .                       .                       legalNameInEnglish  legalNameInEnglish  legalNameInEnglish  legalNameInEnglish          .   ."
            "website                website                 website                 organisationalEmail organisationalEmail organisationalEmail organisationalPhoneNumber   .   ."
            "startDate              endDate                 .                       status              status              .                   .                           .   .";
}

.facilityBasicData .legalName {
    grid-area: legalName;
    --width-by-chars: 80;
}

.facilityBasicData .legalNameInEnglish {
    grid-area: legalNameInEnglish;
    --width-by-chars: 80;
}

.facilityBasicData .publicContactPersonName {
    grid-area: publicContactPersonName;
    /* instead of 80 because of arrangement requirements */
    --width-by-chars: 60;
}

.facilityBasicData .publicContactPersonEmail {
    grid-area: publicContactPersonEmail;
    /* instead of 100 because of arrangement requirements */
    --width-by-chars: 80;
}

.facilityBasicData .organisationalContactPersonName {
    grid-area: organisationalContactPersonName;
    /* instead of 80 because of arrangement requirements */
    --width-by-chars: 60;
}

.facilityBasicData .organisationalContactPersonEmail {
    grid-area: organisationalContactPersonEmail;
    /* instead of 100 because of arrangement requirements */
    --width-by-chars: 80;
}

.facilityBasicData .scoLegalName {
    grid-area: scoLegalName;
    --width-by-chars: 80;
}

.facilityBasicData .scoBusinessName {
    grid-area: scoBusinessName;
    --width-by-chars: 40;
}

.facilityBasicData .independentLegalEntity {
    grid-area: independentLegalEntity;
}
