.scopeData {
    grid-template-columns: repeat(3, max-content) 1fr;
    grid-template-areas:
            "scope scope standard ."
            "description description description description"
            "startDate endDate endDate .";
}

.scopeData .scope {
    grid-area: scope;
    --width-by-chars: 40;
}

.scopeData .standard {
    grid-area: standard;
    --width-by-chars: 10;
}

.scopeData .description {
    grid-area: description;
}

.scopeData .startDate {
    grid-area: startDate;
}

.scopeData .endDate {
    grid-area: endDate;
}
