.oneTimePassword {
    display: flex;
    flex-direction: column;
}

.oneTimePassword .oneTimePassword-form-oneTimePassword {
    margin: 0 0 15px 0;
}

.oneTimePassword .oneTimePassword-form-submit {
    margin: 38px 0 30px;
}

.oneTimePassword .oneTimePassword-form-info {
    align-self: flex-start;
}

.oneTimePassword .oneTimePassword-form-backToLogin {
    margin: 38px 0 30px;
}

.oneTimePassword .skipAdditionalAuthFactor {
    margin-bottom: 20px;
}
