.noteList {
    display: flex;
    flex-direction: column;
    background: var(--primary-background-color);
    box-shadow: 0 0 5px var(--shadow-color);
    border-left: 1px solid var(--header-border-color);
    border-bottom: 1px solid var(--header-border-color);
}

.noteList button {
    color: var(--list-column-header-color);
}

.noteList .addNote {
    background: var(--secondary-background-color);
    padding: var(--small-padding);
    height: unset;
    font-size: var(--14pt);
    border-radius: 0;
    border-bottom: 1px solid var(--header-border-color);
    flex-grow: 0;
}

.noteList ol {
    overflow: auto;
}

.noteList .note {
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    grid-template-rows: min-content min-content max-content;
    grid-template-areas:
            "username   edit    delete"
            "timestamp  edit    delete"
            "text       text    text";
    margin: 15px 15px 0;
    border-bottom: 1px solid var(--header-border-color);
    align-items: flex-start;
}

.noteList .note:last-of-type {
    border-bottom: 0;
}

.noteList .note .username {
    grid-area: username;
    color: var(--primary-text-color);
}

.noteList .note .timestamp {
    grid-area: timestamp;
    color: var(--tertiary-text-color);
    white-space: nowrap;
}

.noteList .note .editedNoteIcon {
    margin-left: 5px;
}

.noteList .note button {
    margin-left: 10px;
}

.noteList .note .editNote {
    grid-area: edit;
}

.noteList .note .deleteNote {
    grid-area: delete;
}

.noteList .note .noteText {
    grid-area: text;
    color: var(--secondary-text-color);
    word-break: break-word;
    white-space: break-spaces;
}
