
tr:hover .gridRow-contextMenu, tr:focus-within .gridRow-contextMenu {
    display: flex;
}

.gridRow-contextMenu {
    flex-direction: row;
    display: none;
    position: absolute;
    z-index: 9;
    height: var(--grid-row-vertical-spacing);
    align-items: center;
}

.gridRow-contextMenu > * {
    color: var(--list-item-menu-color);
    font-size: calc(var(--grid-row-vertical-spacing) - 1px);
    white-space: nowrap;
    line-height: 100%;
    text-decoration: none;
}

.gridRow-contextMenu .separator {
    border-right: 1px solid;
    margin: 0 8px;
    height: 100%;
}

.gridRow-contextMenu .warning {
    color: var(--highlight-color);
}
