.processCategoryPickList {
    --width-by-chars: 40
}

.rawMaterialPickList {
    --width-by-chars: 80
}

.productCategoryPickList {
    --width-by-chars: 40
}

.productDetailPickList {
    --width-by-chars: 40
}

.typeOfMaterialPickList {
    --width-by-chars: 30
}

.countryAreaPickList {
    --width-by-chars: 40
}

.authorisationRolePickList {
    --width-by-chars: 20
}

.stateProvincePickList {
    --width-by-chars: 40;
}

.facilityPickList {
    --width-by-chars: 40;
}

.certificationBodyPickList {
    --width-by-chars: 40;
}

.accreditationBodyPickList {
    --width-by-chars: 40;
}

.standardVersionPickList {
    --width-by-chars: 40;
}

.standardBodyPicklist {
    --width-by-chars: 40;
}

.standardPickList {
    --width-by-chars: 80;
}

.supplyChainOperatorPickList {
    --width-by-chars: 40;
}

.organicMaterialProducerPickList {
    --width-by-chars: 40;
}

.organisationPickList {
    /* Width from SCO/OMP/CB - Picklist */
    --width-by-chars: 40;
}

.scopeCertificateStatusPickList {
    --width-by-chars: 18;
}

.userPickList {
    --width-by-chars: 40;
}

.labelGradePickList {
    --width-by-chars: 40;
}

.preGtbTransactionCertificateStatusPickList {
    --width-by-chars: 18;
}

.transactionCertificateStatusPickList {
    --width-by-chars: 18;
}

.transactionCertificatePickList {
    --width-by-chars: 25;
}

.preGtbTcPickList {
    --width-by-chars: 69;
}
