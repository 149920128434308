.preGtbTcProductMaterialComposition {
    grid-template-columns: repeat(3, min-content) 1fr;
    grid-template-areas:
            "productCategoryPrint   productDetailPrint  labelGrade  ."
            "preGtbTcMaterials      preGtbTcMaterials   preGtbTcMaterials   preGtbTcMaterials";
}

.preGtbTcProductMaterialComposition .productCategoryPrint {
    grid-area: productCategoryPrint;
    --width-by-chars: 40;
}

.preGtbTcProductMaterialComposition .productDetailPrint {
    grid-area: productDetailPrint;
    --width-by-chars: 40;
}

.preGtbTcProductMaterialComposition .labelGrade {
    grid-area: labelGrade;
    --width-by-chars: 40;
}

.preGtbTcProductMaterialComposition .preGtbTcMaterials {
    grid-area: preGtbTcMaterials;
}
